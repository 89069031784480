import { supabase } from "../supabase/client.js";
import axios from "axios";

export const getFile = async (file) => {
  const { data } = supabase.storage.from("jsongen_public").getPublicUrl(`test/${file}`);
  return await axios.get(data.publicUrl, {
    responseType: "arraybuffer",
  });
};

export const getFileUrl = async (file) => {
  return supabase.storage.from("jsongen_public").getPublicUrl(`test/${file}`);
};
