import { useState } from "react";
import PaymentMethods from "./payment_methods/PaymentMethods.js";
import Credits from "./credits/Credits.js";
import BillingHistory from "./history/BillingHistory.js";
import { StripeProvider } from "../../contexts/StripeContext.js";

const tabs = [
  { name: "Credits", href: "#" },
  { name: "Payment methods", href: "#" },
  { name: "Billing history", href: "#" },
];

export default function Billing() {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const handleSelectTab = (tab) => {
    setSelectedTab(tab);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <main className="lg:pl-72 py-10">
        <div className="px-4 sm:px-6 lg:px-8 2xl:px-44">
          <div className="mb-4 border-b border-gray-200 pb-5 sm:pb-0">
            <h3 className="text-left text-base font-semibold leading-6 text-gray-900">
              Billing details
            </h3>
            <div className="mt-3 sm:mt-4">
              <div className="sm:hidden">
                <label htmlFor="current-tab" className="sr-only">
                  Select a tab
                </label>
                <select
                  id="current-tab"
                  name="current-tab"
                  className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={selectedTab.name}
                  onChange={(e) => {
                    const tab = tabs.find((tab) => tab.name === e.target.value);
                    if (tab) {
                      handleSelectTab(tab);
                    }
                  }}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name} value={tab.name}>
                      {tab.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="hidden sm:block">
                <nav className="-mb-px flex space-x-8">
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      onClick={() => handleSelectTab(tab)}
                      className={classNames(
                        selectedTab === tab
                          ? "border-indigo-500 text-indigo-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
                      )}
                      aria-current={selectedTab === tab ? "page" : undefined}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <StripeProvider>
            {selectedTab.name === "Credits" && <Credits />}
            {selectedTab.name === "Payment methods" && (
              <PaymentMethods onAddCredits={() => handleSelectTab(tabs[0])} />
            )}
            {selectedTab.name === "Billing history" && <BillingHistory />}
          </StripeProvider>
        </div>
      </main>
    </>
  );
}
