import { XCircleIcon } from '@heroicons/react/24/outline'
import { Dialog } from '@headlessui/react'

export default function CreditsDialogCheckoutError({ title, text, actionText, onClose }) {
  return (
    <>
      <div>
        <div className="mx-auto mt-6 flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
          <XCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-6 text-center sm:mt-5">
          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
            {title}
          </Dialog.Title>
          <div className="mt-4">
            <p className="text-sm text-gray-500">
              {text}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8 sm:mt-6">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={onClose}
        >
          {actionText}
        </button>
      </div>  
    </>
  );
}