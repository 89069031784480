import React from 'react';
import PaymentMethodIcon from '../../icons/PaymentMethodIcon.js';

export default function PaymentMethodDropdownItem({ paymentMethod }) {
  const formattedMonth = String(paymentMethod.exp_month).padStart(2, '0');
  const formattedYear = String(paymentMethod.exp_year).slice(-2);
  
  return (
    <div className="min-h-8 flex items-center w-full">
      <PaymentMethodIcon brand={paymentMethod?.brand} />
      <div className="w-full flex justify-between items-center">
        <span>•••• {paymentMethod?.last4}</span>
        <p className="font-light text-xs tabular-nums">Expires {formattedMonth}/{formattedYear}</p>
      </div>
    </div>
  );
}