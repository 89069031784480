import { ShieldCheckIcon, BracesIcon, MonitorCheckIcon } from "lucide-react";

export default function ContentSection() {
  return (
    <div className="text-left relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
          />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">Being precise</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Streamlined JSON Schema Integration
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-700">
                By using structured outputs, you can guarantee that the generated data fits your
                exact requirements. The API validates each response against your provided JSON
                schema, offering reliable type-safety.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src="https://fpbtoiiubqwluapvkrhs.supabase.co/storage/v1/object/public/jsongen_public/test/json-schema-example.png?t=2024-09-14T23%3A06%3A13.969Z"
            alt=""
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <ul className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <ShieldCheckIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Robust and Reliable Data Handling
                    </strong>{" "}
                    Our system ensures that every piece of data conforms to your specified schema,
                    providing consistent and dependable results. Tailor your prompts to handle
                    various input scenarios effectively, ensuring your application always receives
                    well-structured data fitting your needs.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <BracesIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Comprehensive Schema Support
                    </strong>{" "}
                    We support a subset of the{" "}
                    <a
                      href="https://json-schema.org/docs"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-semibold text-indigo-600 hover:text-indigo-800"
                    >
                      JSON Schema language
                    </a>
                    , including the types String, Number, Boolean, Integer, Object, Array, Enum and
                    anyOf.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <MonitorCheckIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Simplified Development Process
                    </strong>{" "}
                    Forget about crafting complex prompts to enforce formatting. With structured
                    outputs, you can concentrate on building your application, knowing that data
                    integrity is automatically maintained. This reduces the need for validating and
                    retrying incorrectly formatted responses.
                  </span>
                </li>
              </ul>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
                Enhance Your Data Exchange
              </h2>
              <p className="mt-6">
                Utilizing JSON, one of the most widely adopted data exchange formats, our service
                provides a reliable mechanism for generating structured responses. Whether you're
                integrating user-generated input or predefined data sets, our API ensures
                consistency and adherence to your custom JSON schema.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
