import { Link } from "react-router-dom";
import {
  BanknotesIcon,
  DocumentDuplicateIcon,
  CommandLineIcon,
  ChartBarIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import GithubIcon from "../icons/GithubIcoun";

const actions = [
  {
    title: "Playground",
    href: "/dashboard/playground",
    icon: CommandLineIcon,
    iconForeground: "text-blue-700",
    iconBackground: "bg-blue-50",
    subTitle:
      "Experiment with generating custom JSON data. Design your prompt and upload your files to see instant results.",
  },
  {
    title: "Billing",
    href: "/dashboard/billing",
    icon: BanknotesIcon,
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
    subTitle:
      "Manage your credit balance and payment details. Review invoices and update payment method information.",
  },
  {
    title: "Usage",
    href: "/dashboard/usage",
    icon: ChartBarIcon,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
    subTitle:
      "Track your API usage and monitor your quota. Get insights on your data generation activities.",
  },
  {
    title: "API Reference",
    href: "/docs/api",
    external: true, // Add this flag to indicate it's an external link
    icon: DocumentDuplicateIcon,
    iconForeground: "text-yellow-700",
    iconBackground: "bg-yellow-100",
    subTitle:
      "Explore our API documentation. Learn how to integrate and use our service effectively.",
  },
  {
    title: "GitHub sample projects",
    href: "#",
    icon: GithubIcon,
    iconForeground: "text-gray-700",
    iconBackground: "bg-gray-100",
    subTitle:
      "Coming soon. Find sample projects and code snippets. Quickly integrate our API into your applications. Check out our API Reference in the meantime.",
  },
  {
    title: "Home",
    href: "/",
    icon: HomeIcon,
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
    subTitle: "Go back to home page to find more information about our services.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard() {
  return (
    <div className="lg:pl-72">
      <div className="px-5 pt-10 pb-2 2xl:px-44">
        <div className="text-left flex-col w-full max-w-2xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Welcome to your Dashboard
          </h2>
          <p className="mt-2 text-md leading-8 text-gray-600 sm:text-lg">
            Access and manage all your tools and usage insights for seamless JSON data generation.
          </p>
        </div>
        <div className="flex justify-center text-left mt-5">
          <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
            {actions.map((action, actionIdx) => (
              <div
                key={action.title}
                className={classNames(
                  actionIdx === 0 ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none" : "",
                  actionIdx === 1 ? "sm:rounded-tr-lg" : "",
                  actionIdx === actions.length - 2 ? "sm:rounded-bl-lg" : "",
                  actionIdx === actions.length - 1
                    ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
                    : "",
                  "group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
                )}
              >
                <div>
                  <span
                    className={classNames(
                      action.iconBackground,
                      action.iconForeground,
                      "inline-flex rounded-lg p-3 ring-4 ring-white"
                    )}
                  >
                    <action.icon className="h-6 w-6" aria-hidden="true" />
                  </span>
                </div>
                <div className="mt-8">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    {action.external ? (
                      <a
                        href={action.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="focus:outline-none"
                      >
                        {/* Extend touch target to entire panel */}
                        <span className="absolute inset-0" aria-hidden="true" />
                        {action.title}
                      </a>
                    ) : (
                      <Link to={action.href} className="focus:outline-none">
                        {/* Extend touch target to entire panel */}
                        <span className="absolute inset-0" aria-hidden="true" />
                        {action.title}
                      </Link>
                    )}
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">{action.subTitle}</p>
                </div>
                <span
                  className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                  aria-hidden="true"
                >
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                  </svg>
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
