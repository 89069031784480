import axios from "axios";

export const fetchTokenUsage = async (accessToken, year, month, timezone) => {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/usage/tokens`, {
    params: {
      year,
      month,
      timezone,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
};
