import { useEffect, useRef } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";

const customTheme = {
  ...prism,
  property: { color: "#000" },
  string: { color: "#008000" },
  punctuation: { color: "#000000" },
  number: { color: "blue" },
  boolean: { color: "#800080" },
  null: { color: "#808080" },
  function: { color: "#3949AB" },
  "function-variable": { color: "#3949AB" },
  "class-name": { color: "#3949AB" },
};

export default function CodeOutput({ code, language }) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [code]);

  return (
    <div className="w-full h-full bg-white overflow-y-scroll" ref={containerRef}>
      <SyntaxHighlighter
        language={language}
        style={customTheme}
        showLineNumbers
        customStyle={{
          margin: 0,
          padding: "8px",
          fontSize: "0.875rem",
          lineHeight: "1.5",
          fontFamily: "'Consolas', 'Monaco', 'Andale Mono', 'Ubuntu Mono', monospace",
          background: "white",
          minWidth: "100%",
          display: "inline-block",
        }}
        lineNumberStyle={{
          minWidth: "2.5em",
          paddingRight: "0.8em",
          textAlign: "left",
        }}
      >
        {code}
      </SyntaxHighlighter>
    </div>
  );
}
