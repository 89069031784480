import { useToast } from "../../shadcn/components/ui/use-toast.js";

const toastMessages = {
  UNKNOWN_ERROR: {
    variant: "destructive",
    title: "Uh oh! Something went wrong.",
    description: "An unknown error occured. Please try again.",
  },
  MISSING_INPUTS: {
    variant: "destructive",
    title: "Missing inputs",
    description:
      "Make sure to enter your prompt as well as an output schema. You can also try out our example data.",
  },
  REQUEST_ERROR: {
    variant: "destructive",
    title: "Uh oh! Something went wrong.",
    description: "There was a problem with your request.",
  },
  CLIPBOARD_SUCCESS: {
    variant: "success",
    title: "Copied to clipboard",
    description: "The API key has been copied to your clipboard.",
  },
  CLIPBOARD_ERROR: {
    variant: "destructive",
    title: "Uh oh! Something went wrong.",
    description: "We could not copy the content to your clipboard.",
  },
  PROMPT_SCHEMA_ERROR: {
    variant: "destructive",
    title: "Missing inputs",
    description:
      "Make sure to enter your prompt as well as an output schema. You can also try out our example data.",
  },
  // Add more predefined messages here...
};

export const useToastMessages = () => {
  const { toast } = useToast();

  const showToast = (key) => {
    const message = toastMessages[key];
    if (message) {
      toast(message);
    }
  };

  return { showToast };
};
