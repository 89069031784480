import { useStripe } from "../../../contexts/StripeContext.js";
import PaymentMethodItem from "../payment_methods/PaymentMethodItem.js";
import PaymentMethodsEmpty from "../payment_methods/PaymentMethodsEmpty.js";

export default function PaymentMethods({ onAddCredits }) {
  const { paymentMethodId, paymentMethods, updatePaymentMethodId, updatePaymentMethods } = useStripe();

  const deletePaymentMethod = (id) => {
    const updatedPaymentMethods = paymentMethods.filter(method => method.id !== id);
    if (paymentMethodId === id) {
      updatePaymentMethodId(updatedPaymentMethods[0]?.id ?? null);
    }
    updatePaymentMethods(updatedPaymentMethods);
  };

  return (
    <>
      {
        (paymentMethods && paymentMethods.length > 0) ? 
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-left font-semibold leading-6 text-gray-900">Payment methods</h3>
              <div className="mt-5 flex flex-col gap-5">
                {
                  paymentMethods.map(paymentMethod => 
                    <PaymentMethodItem 
                      key={paymentMethod.id} 
                      paymentMethod={paymentMethod}
                      onDeletePaymentMethod={deletePaymentMethod}
                    />
                  )
                } 
              </div>
            </div>
          </div>
          :
          <PaymentMethodsEmpty onAddCredits={onAddCredits}/>
      }
    </>
  )
}