import { useDropzone } from "react-dropzone";
import { motion } from "framer-motion";
import {
  DocumentPlusIcon,
  PaperClipIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { Trash2, Download } from "lucide-react";

export default function Dropzone({ file, fileError, setFile, onDropAccepted, onDropRejected }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
      "application/pdf": [".pdf"],
      "application/json": [".json"],
      "application/xml": [".xml"],
      "application/csv": [".csv"],
      "text/plain": [".txt"],
      "text/html": [".html"],
      "text/csv": [".csv"],
    },
    onDropAccepted: onDropAccepted,
    onDropRejected: onDropRejected,
  });

  const handleDownloadClick = (event) => {
    event.stopPropagation();

    if (file) {
      const url = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      link.download = file.name;
      link.click();
      URL.revokeObjectURL(url);
    }
  };

  const handleTrashClick = (event) => {
    event.stopPropagation();
    setFile(null);
  };

  return (
    <div className="w-full my-2">
      <motion.div
        {...getRootProps()}
        className={`flex items-center justify-between border-2 border-dashed rounded-lg transition-colors duration-300 ease-in-out cursor-pointer ${
          isDragActive
            ? "border-indigo-400 bg-indigo-50"
            : "border-gray-300 hover:border-indigo-300 hover:bg-gray-50"
        }`}
        whileHover={{ scale: 1.01 }}
        whileTap={{ scale: 0.98 }}
      >
        <input {...getInputProps()} />
        <div className="flex-grow flex justify-center items-center p-4">
          {fileError ? (
            <DropzoneText
              icon="error"
              text="Only PNG, JPEG, and PDF files are supported"
              className="h-5 w-5 text-red-500"
            />
          ) : file ? (
            <DropzoneText
              icon="paperClipIcon"
              text={file.name}
              className="h-5 w-5 text-indigo-700"
            />
          ) : (
            <DropzoneText
              icon="documentPlusIcon"
              text="Click or drop a file here to upload"
              className="h-5 w-5 text-gray-600"
            />
          )}
        </div>
        {file && (
          <div className="flex items-center mr-2">
            <button
              type="button"
              className="p-2 hover:bg-gray-200 rounded-full"
              onClick={handleDownloadClick}
            >
              <Download className="h-5 w-5 text-gray-600" />
            </button>
            <button
              type="button"
              className="p-2 hover:bg-gray-200 rounded-full"
              onClick={handleTrashClick}
            >
              <Trash2 className="h-5 w-5 text-red-600" />
            </button>
          </div>
        )}
      </motion.div>
    </div>
  );
}

function DropzoneText({ icon, text, ...props }) {
  const icons = {
    paperClipIcon: <PaperClipIcon {...props} />,
    documentPlusIcon: <DocumentPlusIcon {...props} />,
    error: <ExclamationTriangleIcon {...props} />,
  };

  return (
    <div className="flex justify-center items-center">
      {icons[icon]}
      <p className="ml-2 text-sm text-gray-500">{text}</p>
    </div>
  );
}
