import { useState, useEffect } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useAuth } from "../../contexts/AuthContext.js";
import { Toaster } from "../../shadcn/components/ui/toaster.jsx";
import { fetchTokenUsage } from "../../services/usageservice.js";
import CustomBarChart from "../ui/CustomBarChart.js";
import CustomLineChart from "../ui/CustomLineChart.js";

const nameOfMonths = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "Decemeber",
};

function transformValue(value) {
  if (typeof value === "number") {
    if (!Number.isInteger(value)) {
      value = value.toFixed(2);
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return value;
}

export default function Usage() {
  const { session } = useAuth();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11, so we add 1

  const [usage, setUsage] = useState(null);
  const [totals, setTotals] = useState(null);
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState(currentMonth);

  useEffect(() => {
    const getTokenUsage = async () => {
      try {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const data = await fetchTokenUsage(
          session.access_token,
          year,
          month.toString().padStart(2, "0"),
          timezone
        );
        setUsage(data.usageResponse.usage);
        setTotals(data.usageResponse.totals);
      } catch (error) {}
    };

    getTokenUsage();
  }, [session, month, year]);

  return (
    <div className="lg:pl-80 p-2 lg:p-10 lg:h-full w-full flex flex-col gap-5">
      <Toaster />
      <div className="pl-2 pt-1 lg:pl-0 lg:pt-0 text-left flex flex-col lg:flex-row gap-2 items-stretch min-w-full max-w-2xl">
        <div className="flex-grow">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Usage overview
          </h2>
          <div>
            <p className="mt-2 text-md text-gray-600 sm:text-lg">
              Monitor and Analyze Your Service Utilization
            </p>
            <p className="text-sm text-gray-600">
              Detailed Insights into Token Consumption, Credit Expenditure, and Request Volumes
            </p>
          </div>
        </div>
        <div className="flex-none flex flex-col justify-end">
          <Navigator month={month} setMonth={setMonth} year={year} setYear={setYear} />
        </div>
      </div>
      <div className="h-[300px] lg:h-1/2">
        {usage && usage.length > 0 && (
          <CustomBarChart
            header="Tokens used"
            data={usage}
            total={totals ? transformValue(totals["tokens"]) : null}
          />
        )}
      </div>
      <div className="lg:h-1/2 flex-row lg:flex gap-5">
        <div className="h-[300px] lg:h-full lg:w-1/2 mb-5 lg:mb-0">
          {usage && usage.length > 0 && (
            <CustomLineChart
              header="Credits spent"
              data={usage}
              total={totals ? transformValue(totals["cost"]) + " USD" : null}
              xAxisDataKey="created"
              yAxisDataKey="cost"
            />
          )}
        </div>
        <div className="h-[300px] lg:h-full lg:w-1/2">
          {usage && usage.length > 0 && (
            <CustomLineChart
              header="Number of requests"
              data={usage}
              total={totals ? transformValue(totals["requests"]) : null}
              xAxisDataKey="created"
              yAxisDataKey="requests"
            />
          )}
        </div>
      </div>
    </div>
  );
}

function Navigator({ month, setMonth, year, setYear }) {
  const updateMonth = (inc) => {
    setMonth((prev) => {
      let newMonth = prev + inc;
      let newYear = year;
      if (newMonth < 1) {
        newMonth = 12;
        newYear--;
      } else if (newMonth > 12) {
        newMonth = 1;
        newYear++;
      }
      setYear(newYear);
      return newMonth;
    });
  };

  return (
    <div>
      <nav aria-label="Pagination" className="isolate inline-flex -space-x-px rounded-md shadow-sm">
        <button
          className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => updateMonth(-1)}
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
        </button>
        <div
          aria-current="page"
          className="w-36 relative z-10 inline-flex items-center text-center border px-4 py-2 text-sm font-semibold text-gray-800"
        >
          <p className="w-full">
            {nameOfMonths[month]} {year}
          </p>
        </div>
        <button
          className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => updateMonth(1)}
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon aria-hidden="true" className="h-5 w-5" />
        </button>
      </nav>
    </div>
  );
}
