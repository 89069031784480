import { InformationCircleIcon } from '@heroicons/react/20/solid'

export default function Info({ text, linkTo }) {
  return (
    <div className="rounded-md bg-indigo-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-left text-sm text-indigo-700">{text}</p>
          {
            linkTo &&
              <p className="mt-3 text-sm md:ml-6 md:mt-0">
                <button className="whitespace-nowrap font-medium text-indigo-700 hover:text-indigo-600">
                  Details
                  <span aria-hidden="true"> &rarr;</span>
                </button>
              </p>
          }
        </div>
      </div>
    </div>
  )
}