import axios from "axios";

export const fetchCustomer = async (accessToken) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_BASE_URL}/api/customers/customer`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
};

export const createCustomer = async (userId, email) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/customers/create`,
      { userId, email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {}
};

export const fetchPaymentMethods = async (accessToken, customerId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_BASE_URL}/api/customers/payment_methods`,
    {
      params: {
        customerId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
};

export const fetchCredits = async (accessToken) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_BASE_URL}/api/customers/credits`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
};

export const fetchApiKey = async (accessToken) => {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/customers/key`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
};

export const updateApiKey = async (accessToken) => {
  const response = await axios.put(
    `${process.env.REACT_APP_SERVER_BASE_URL}/api/customers/key`,
    {}, // Empty object as the request body
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
};

export const fetchApiKeyShort = async (accessToken) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_BASE_URL}/api/customers/key/short`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
};

export const createNewsletterSubscriber = async (email) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/customers/newsletter`,
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};
