import { useAuth } from "../../../contexts/AuthContext.js";
import PaymentMethodIcon from "../../icons/PaymentMethodIcon";

export default function PaymentMethodItem({ paymentMethod, onDeletePaymentMethod }) {
  const { session } = useAuth();

  const formattedMonth = String(paymentMethod?.exp_month).padStart(2, "0");
  const formattedYear = String(paymentMethod?.exp_year).slice(-2);
  const formattedBrand =
    paymentMethod?.brand?.charAt(0).toUpperCase() + paymentMethod?.brand?.slice(1);

  const handleDeletePaymentMethod = (paymentMethod) => {
    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/customers/payment_methods/detach`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.access_token}`,
      },
      body: JSON.stringify({ paymentMethodId: paymentMethod.id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Could not detach payment method");
        }
        return response.json();
      })
      .then((data) => {
        onDeletePaymentMethod(data.paymentMethodId);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="rounded-md bg-gray-100 px-6 py-5 sm:flex sm:items-start sm:justify-between">
      <div className="sm:flex sm:items-start">
        <PaymentMethodIcon brand={paymentMethod.brand} />
        <div className="text-left mt-3 sm:ml-4 sm:mt-0">
          <div className="text-sm font-medium text-gray-900">Ending with {paymentMethod.last4}</div>
          <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
            <div>
              Expires {formattedMonth}/{formattedYear}
            </div>
            <span className="hidden sm:mx-2 sm:inline" aria-hidden="true">
              &middot;
            </span>
            <div className="mt-1 sm:mt-0">{formattedBrand}</div>
          </div>
        </div>
      </div>
      <div className="flex mt-4 sm:ml-6 sm:mt-0 sm:flex-shrink-0 justify-end">
        <button
          type="button"
          onClick={() => handleDeletePaymentMethod(paymentMethod)}
          className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Delete
        </button>
      </div>
    </div>
  );
}
