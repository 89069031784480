// utils/dateFormatter.js
export const formatDateTime = (timestamp) => {
  const date = new Date(timestamp);

  const dateOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  };

  const timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(date);
  const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(date);

  return `${formattedDate} ${formattedTime}`;
};
