import axios from "axios";

export const fetchCharges = async (accessToken, customerId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/payments/charges`,
      {
        params: { customerId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export const createPaymentIntent = async (accessToken, customerId, paymentMethodId, amount) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SERVER_BASE_URL}/api/payments/intent/create`,
    { customerId, paymentMethodId, amount },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
};

export const confirmPaymentIntent = async (accessToken, paymentIntentId, paymentMethodId) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SERVER_BASE_URL}/api/payments/intent/confirm`,
    { paymentIntentId, paymentMethodId },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
};
