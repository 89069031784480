import React from "react";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../shadcn/components/ui/resizable.jsx";

export default function ResizeableSplitView({ children, activeChildIndex }) {
  const [firstChild, secondChild] = React.Children.toArray(children);

  return (
    <>
      {/* Desktop view */}
      <div className="w-full hidden xl:block">
        <ResizablePanelGroup direction="horizontal" className="rounded-lg border">
          <ResizablePanel minSize={35} defaultSize={65}>
            {firstChild}
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel minSize={35} defaultSize={35}>
            {secondChild}
          </ResizablePanel>
        </ResizablePanelGroup>
      </div>
      {/* Mobile view */}
      <div className="w-full block xl:hidden">
        {activeChildIndex === 1 ? firstChild : secondChild}
      </div>
    </>
  );
}
