import beautify from "js-beautify/js";

export const getPreviewAreaContent = (language) => {
  switch (language) {
    case "curl":
      return "curl -X 'POST' 'https://jsongen.com/api/generate'\n  -H 'accept: application/json'\n  -H 'Content-Type: multipart/form-data'\n  -F 'prompt=string'\n  -F 'schema=@/path/to/file;type=application/json'\n  -F 'attachment=@/path/to/file;type=YOUR_ATTACHMENT_MIME_TYPE'";
    case "javascript":
      return beautify(
        `const fs = require('fs');

        const prompt = "Your prompt here";
  
        const schema = {};
  
        const schemaContent = JSON.stringify(schema);
        const schemaFile = new Blob([schemaContent], {
          type: "application/json",
        });
  
        const attachment = fs.createReadStream("/path/to/file");
  
        const form = new FormData();
        form.append("prompt", prompt);
        form.append("schema", schemaFile);
        form.append("attachment", attachment);
  
        const response = await fetch("https://jsongen.com/api/generate", {
          method: "POST",
          body: form,
          headers: {
            "X-API-KEY": YOUR_API_KEY,
          }
        });`,
        { indent_size: 2 }
      );
    case "python":
      return `import requests
import json

prompt = "Your prompt here"

schema = {}

schema_content = json.dumps(schema)

with open('path/to/file', 'rb') as file:
  form_data = {
      'prompt': (None, prompt),
      'schema': ('schema.json', schema_content, 'application/json'),
      'attachment': ('file', file, 'YOUR_ATTACHMENT_MIME_TYPE')
  }

  response = requests.post(
      'https://jsongen.com/api/generate',
      files=form_data,
      headers={
          'X-API-KEY': 'YOUR_API_KEY'
      }
  )`;
    default:
      return "";
  }
};
