import { useState } from "react";
import { Link } from "react-router-dom";
import { useStripe } from "../../../contexts/StripeContext.js";
import { formatNumber } from "../../../utils/numbers.js";
import Info from "../../ui/Info.js";
import CreditsDialog from "./CreditsDialog.js";

export default function Credits() {
  const { creditBalance, creditBalanceLoading, creditBalanceError } = useStripe();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSetDialogOpen = (dialogOpen) => {
    setDialogOpen(dialogOpen);
  };

  return (
    <div className="shadow sm:rounded-lg p-5">
      <>
        <CreditsDialog open={dialogOpen} setOpen={handleSetDialogOpen} />
        <div className="w-full">
          <Info text="Please add credits to your balance if you want to use our services on prepaid mode. Automated billing will be available in a future release." />
        </div>
        <div className="mt-4 flex flex-col items-start">
          <p>{"Current credit balance"}</p>
          {creditBalanceLoading || creditBalanceError ? (
            <div role="status" className="w-26 mr-3">
              <div className="h-10 w-32 bg-gray-100 dark:bg-gray-600 rounded-md animate-blur-pulse"></div>
            </div>
          ) : (
            <p className="text-4xl text-gray-900">{`$ ${formatNumber(creditBalance)}`}</p>
          )}
          <div className="flex gap-x-2">
            <button
              className="flex justify-center rounded-md mt-3 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-600 bg-indigo-600 hover:bg-indigo-500"
              onClick={() => handleSetDialogOpen(true)}
            >
              Add credits now
            </button>
            <Link
              to="/dashboard/usage"
              className="flex justify-center rounded-md mt-3 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-600 hover:bg-gray-800"
            >
              View usage
            </Link>
          </div>
        </div>
      </>
    </div>
  );
}
