import { useState, useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext.js";
import { useStripe } from "../../../contexts/StripeContext.js";
import BillingHistoryItem from "./BillingHistoryItem.js";
import Spinner from "../../icons/Spinner.js";
import { fetchCharges } from "../../../services/paymentservice.js";

export default function BillingHistory() {
  const { session } = useAuth();
  const { customerId } = useStripe();

  const [charges, setCharges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getCharges = async () => {
      try {
        const { data, error } = await fetchCharges(session.access_token, customerId);
        if (data) {
          setCharges(data.charges);
        } else if (error) {
          setCharges([]);
          setError(true);
        }
        setLoading(false);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    getCharges();
  }, [session, customerId]);

  return (
    <div className="text-left bg-white shadow sm:rounded-lg px-4 pt-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Invoices</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of your recent payment activity. Download your invoices for your records.
          </p>
        </div>
      </div>
      <div className="-mx-4 mt-8 sm:-mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                Invoice
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Amount
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Created
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Status
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {loading && (
              <tr>
                <td colSpan="5" className="py-4 text-center">
                  <div className="w-full h-20 flex justify-center items-center">
                    <Spinner width="w-8" height="h-8" />
                  </div>
                </td>
              </tr>
            )}
            {!loading && error && (
              <tr>
                <td colSpan="5" className="px-4 py-4 text-center">
                  <div className="w-full flex sm:justify-center text-sm text-gray-700">
                    Oops! Something went wrong. Please try again later.
                  </div>
                </td>
              </tr>
            )}
            {!loading && !error && charges?.length === 0 && (
              <tr>
                <td colSpan="5" className="px-4 py-4 text-center">
                  <div className="w-full flex sm:justify-center text-sm text-gray-700">
                    No recent payments
                  </div>
                </td>
              </tr>
            )}
            {charges.length > 0 &&
              charges.map((charge) => (
                <BillingHistoryItem
                  receiptNumber={charge.receipt_number}
                  receiptUrl={charge.receipt_url}
                  amount={charge.amount}
                  currency={charge.currency}
                  created={charge.created}
                  status={charge.status}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
