import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext.js";

function ProtectedRoute({ children, redirectTo }) {
  const { session } = useAuth();

  if (!session) {
    return <Navigate to={redirectTo} replace />;
  }

  return children;
}

export default ProtectedRoute;
