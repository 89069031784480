import { useState } from "react";
import { Button } from "../../shadcn/components/ui/button.jsx";
import { CircleHelp, RotateCw, Copy, Info } from "lucide-react";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
} from "../../shadcn/components/ui/tooltip.jsx";
import Modal from "../ui/Modal.js";

export function PlaygroundApiKey({ shortApiKey, handleCopyApiKey, handleRegenerateApiKey }) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        onAction={handleRegenerateApiKey}
        title="Regenerate API Key"
        description="Are you sure you want to regenerate your API key? Your current API key will be permanently deleted and replaced with a new one. This action cannot be undone."
        actionLabel="Regenerate"
      ></Modal>
      <div className="w-full md:w-1/3">
        <div className="flex flex-row gap-1 items-center mb-1">
          <h2 className="text-xs text-gray-800 font-semibold text-nowrap">Your API key</h2>
          <ApiKeyTooltip />
        </div>

        <div className="relative flex items-center h-10 w-full rounded-md text-indigo-700 border border-indigo-300 bg-background pl-3 py-2 text-sm">
          <div className="overflow-hidden whitespace-nowrap pr-20 font-consolas">{shortApiKey}</div>
          <div className="absolute right-0 flex flex-row bg-white pr-1">
            <Button size="smallIcon" variant="ghost" onClick={() => setModalOpen(true)}>
              <RotateCw className="h-4 w-4 text-indigo-700" />
            </Button>
            <Button size="smallIcon" variant="ghost" onClick={handleCopyApiKey}>
              <Copy className="h-4 w-4 text-indigo-700" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

function ApiKeyTooltip() {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={100}>
        <TooltipTrigger>
          <CircleHelp className="m-1 h-4 w-4 text-indigo-700" />
        </TooltipTrigger>
        <TooltipContent className="w-80 p-0">
          <div className="bg-indigo-100 p-3 rounded-t-md flex items-center">
            <Info className="h-5 w-5 text-indigo-700 mr-2" />
            <h3 className="font-semibold text-indigo-800">API Key Security</h3>
          </div>
          <div className="p-3">
            Keep your API key confidential and never share it publicly. You can regenerate your key
            at any time, which will invalidate the old one. Protect your account and data by keeping
            your key secure.
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
