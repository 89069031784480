import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useStripe } from '../../../contexts/StripeContext.js';
import Spinner from '../../icons/Spinner.js';
import PaymentMethodsDropdownItem from './PaymentMethodsDropdownItem.js';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function PaymentMethodsDropdown() {
  const { paymentMethodId, paymentMethods, paymentMethodsLoading, updatePaymentMethodId } = useStripe();

  const isDisabled = !paymentMethods || paymentMethods.length === 0;

  const handleUpdatePaymentMethod = (method) => {
    updatePaymentMethodId(method.id);
  }

  const paymentMethod = paymentMethods.find(
    paymentMethod => paymentMethod.id === paymentMethodId
  );

  return (
    <Menu as="div" className="w-full relative inline-block text-left">
      <div>
        <Menu.Button 
          disabled={isDisabled}
          className={`flex items-center w-full gap-x-1.5 rounded-md px-3 py-2 font-semibold shadow-sm ring-1 ring-inset 
            ${isDisabled 
              ? 'cursor-not-allowed ring-gray-200' 
              : 'bg-white text-gray-900 hover:bg-gray-50 ring-gray-300'
            }`}>
          
          <span className="w-full min-h-8 flex items-center gap-x-1.5">
            {paymentMethodsLoading && <Spinner />}
            {!paymentMethodsLoading && isDisabled && 'No payment methods found'}
            {!paymentMethodsLoading && !isDisabled && !paymentMethodId && <PaymentMethodsDropdownItem paymentMethod={paymentMethods[0]}/>}
            {!paymentMethodsLoading && !isDisabled && paymentMethodId && <PaymentMethodsDropdownItem paymentMethod={paymentMethod}/>}
          </span>

          <ChevronDownIcon className="ml-auto -mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-300"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="w-full mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {paymentMethods.map((method) => (
            <div key={method.id} className="py-1">
              <Menu.Item className="w-full">
                {({ active }) => (
                  <button
                    onClick={() => handleUpdatePaymentMethod(method)}
                    className={classNames(
                      active ? 'w-full bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm'
                    )}
                  >
                    <PaymentMethodsDropdownItem paymentMethod={method}/>
                  </button>
                )}
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}