import { useState } from 'react';
import AuthForm from '../components/auth/AuthForm.js';
import SentMailMessage from '../components/auth/SentMailMessage.js';

export default function AuthPage({ type }) {
  const [ formMessageData, setFormMessageData ] = useState(undefined);

  return (
    formMessageData ? 
      <SentMailMessage 
        headerText="Check your email"
        mainText="We just sent a verification link to"
        linkText="Go to Login"
        linkTo="/signin"
        emailTo={formMessageData} 
      /> 
      : 
      <AuthForm type={type} handleFormMessage={setFormMessageData} />
  )
}