import { useState, useEffect, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useStripe } from '../../../contexts/StripeContext.js';
import CreditsDialogOrder from './CreditsDialogOrder.js';
import PaymentDetailsFormStripe from '../payments/PaymentDetailsFormStripe.js';
import CreditsDialogCheckout from './CreditsDialogCheckout.js';
import CreditsDialogCheckoutSuccess from './CreditsDialogCheckoutSuccess.js';
import CreditsDialogCheckoutError from './CreditsDialogCheckoutError.js';

export default function CreditsDialog({ open, setOpen }) {
  const { paymentMethodsError } = useStripe();
  const [ currentDialog, setCurrentDialog ] = useState('choose_amount');

  const handleResetDialogState = () => {
    setCurrentDialog('choose_amount');
  }

  useEffect(() => {
    if (paymentMethodsError) {
      setCurrentDialog('payment_methods_error');  
    }
  }, [paymentMethodsError]);

  const dialogComponents = {
    choose_amount: (
      <CreditsDialogOrder 
        onAddPaymentMethod={() => setCurrentDialog('add_payment_method')} 
        onCheckout={() => setCurrentDialog('confirm_payment')}
        onPaymentMethodsError={() => setCurrentDialog('payment_methods_error')}
      />
    ),
    add_payment_method: (
      <PaymentDetailsFormStripe 
        onSuccess={() => setCurrentDialog('choose_amount')}
      />
    ),
    confirm_payment: (
      <CreditsDialogCheckout 
        onSuccess={() => setCurrentDialog('payment_successful')} 
        onError={() => setCurrentDialog('payment_error')} 
        onCancel={() => setCurrentDialog('choose_amount')}
      />
    ),
    payment_successful: (
      <CreditsDialogCheckoutSuccess 
        onClose={() => setOpen(false)} 
      />
    ),
    payment_methods_error: (
      <CreditsDialogCheckoutError 
        title="Oops."
        text="Something went wrong. Please try again later"
        actionText="Go back to dashboard"
        onClose={() => setOpen(false)} 
      />
    ),
    payment_error: (
      <CreditsDialogCheckoutError 
        title="Payment failed"
        text="We could not process your payment. Please use a different payment method or try again later."
        actionText="Go back to dashboard"
        onClose={() => setOpen(false)} 
      />
    )
  };

  return (
    <Transition.Root 
      show={open} 
      as={Fragment} 
      afterLeave={handleResetDialogState}
    >
      <Dialog 
        as="div" 
        className="relative z-50" 
        onClose={() => setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                {dialogComponents[currentDialog]}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}