import React from "react";
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts";
import CustomTooltip from "./CustomChartTooltip.js";

function formatNumber(x) {
  if (x < 1000) {
    return x.toString();
  } else if (x >= 1000 && x < 1000000) {
    return (x / 1000).toFixed(0) + "k";
  } else if (x >= 1000000) {
    return (x / 1000000).toFixed(1) + "M";
  }
}

export default function CustomBarChart({ header, data, total }) {
  return (
    <div className="flex flex-col h-full shadow sm:rounded-lg text-left">
      <div className="flex flex-row justify-between items-center w-full p-3">
        <div className="font-bold text-lg">{header}</div>
        {total && <div className="text-sm text-gray-500">{`${total} total`}</div>}
      </div>
      {data && (
        <div className="flex-1">
          <ResponsiveContainer>
            <BarChart
              data={data}
              margin={{
                top: 20,
                left: -10,
                right: 15,
                bottom: 15,
              }}
            >
              <CartesianGrid vertical={false} stroke="#eeeeee" />
              <XAxis
                dataKey="created"
                interval={2}
                tick={{ fontSize: 11, dy: 5 }}
                tickFormatter={(tick) => parseInt(tick.split("-")[2])}
              />
              <YAxis tick={{ fontSize: 11, dx: -5 }} tickFormatter={(tick) => formatNumber(tick)} />
              <Tooltip cursor={{ fill: "#eeeeee" }} content={<CustomTooltip />} />
              <Bar dataKey="prompt_tokens" stackId="a" fill="#7986CB" />
              <Bar dataKey="completion_tokens" stackId="a" fill="#BDBDBD" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
}
