import { EnvelopeIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import JsongenLogo from "../icons/JsongenLogo.js";

export default function SentMailMessage({ headerText, mainText, linkText, linkTo, emailTo }) {
  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <JsongenLogo textColor="text-indigo-700" braceColor="text-gray-600" />
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[560px]">
          <div className="flex flex-col items-center bg-white py-12 shadow sm:rounded-lg sm:px-12">
            <EnvelopeIcon className="h-12 w-12"></EnvelopeIcon>
            <h2 className="mt-8 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              {headerText}
            </h2>
            <p className="mt-4">
              {mainText} {emailTo}
            </p>
            <a
              href={linkTo}
              className="mt-10 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {linkText}
              <ArrowRightIcon className="-mr-0.5 h-4 w-4" aria-hidden="true" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
