import { useState, useEffect } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shadcn/components/ui/select.jsx";
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from "../../shadcn/components/ui/tooltip.jsx";
import { Image, FileChartColumn, Sheet, CircleHelp, Info } from "lucide-react";
import { PlaygroundApiKey } from "./PlaygroundApiKey.js";

const testingOptions = [
  {
    label: "Handwritten Letter",
    value: "letter",
    file: "handwritten_letter.jpg",
    type: <Image className="w-5 h-5 mr-1" />,
  },
  {
    label: "Savings Account Overview",
    value: "savings-account",
    file: "savings_account.pdf",
    type: <FileChartColumn className="w-5 h-5 mr-1" />,
  },
  {
    label: "Bank Transactions",
    value: "bank-transactions",
    file: "bank_transactions.csv",
    type: <Sheet className="w-5 h-5 mr-1" />,
  },
];

export default function PlaygroundTestData({
  onOptionSelect,
  resetTrigger,
  disabled,
  shortApiKey,
  handleCopyApiKey,
  handleRegenerateApiKey,
}) {
  const [activeOption, setActiveOption] = useState("");

  useEffect(() => {
    setActiveOption("");
  }, [resetTrigger]);

  const handleOptionClick = (option) => {
    setActiveOption(option.value);
    onOptionSelect(option.file);
  };

  const handleOptionChange = (value) => {
    const selectedOption = testingOptions.find((option) => option.value === value);
    if (selectedOption) {
      handleOptionClick(selectedOption);
    }
  };

  return (
    <div className="w-full mx-auto mb-4">
      <div className="bg-indigo-50 rounded-lg p-3 shadow-md">
        <div className="flex flex-row gap-2">
          <PlaygroundApiKey
            shortApiKey={shortApiKey}
            handleCopyApiKey={handleCopyApiKey}
            handleRegenerateApiKey={handleRegenerateApiKey}
          />
          <div className="w-full md:w-2/3">
            <div className="flex flex-row gap-1 items-center mb-1">
              <h2 className="text-xs text-gray-800 font-semibold text-nowrap">Testing data</h2>
              <TestDataTooltip />
            </div>
            <Select onValueChange={handleOptionChange} value={activeOption} disabled={disabled}>
              <SelectTrigger className="w-full bg-white text-indigo-700 border-indigo-300 focus:border-indigo-300">
                <SelectValue placeholder="Select an example" />
              </SelectTrigger>
              <SelectContent>
                {testingOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    <div className="flex flex-row gap-1 items-center text-indigo-700">
                      {option.type}
                      {option.label}
                    </div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
}

export function getTestPrompt(option) {
  const prompts = {
    "handwritten_letter.jpg":
      "Analyze a handwritten letter in PDF format. Extract key information such as the date, category, and address details. Determine if the address is located in the United States. Additionally, capture the full text and provide a concise summary of the letter's content.",
    "savings_account.pdf":
      "Extract the following details from the savings account PDF and format them according to the provided JSON schema: account holder information, bank details, account details (type, interest rate, duration, etc.), and restrictions on deposits and withdrawals.",
    "bank_transactions.csv":
      "Transform the following CSV data into JSON format based on this schema. Each row represents a bank account transaction with fields like transaction ID (UUID), amount (number), date (date-time string), and tags (comma-separated strings). ",
  };
  return prompts[option];
}

export function getTestSchema(option) {
  const schemas = {
    "handwritten_letter.jpg": {
      type: "object",
      properties: {
        date: {
          type: "string",
        },
        category: {
          type: "string",
          enum: ["INVITATION", "APPLICATION", "DECLINE", "OFFER"],
          description:
            "The type of category the letter is assigned to (e.g., an invitation or an offer).",
        },
        address: {
          type: "object",
          properties: {
            firstName: {
              type: "string",
            },
            lastName: {
              type: "string",
            },
            company: {
              type: "string",
            },
            street: {
              type: "string",
            },
            streetNumber: {
              type: "integer",
            },
            city: {
              type: "string",
            },
            zipCode: {
              type: "integer",
            },
            isUs: {
              type: "boolean",
              description:
                "Indicates if the address is located in the United States (true if yes).",
            },
          },
          description: "The address details of the recipient.",
        },
        fullText: {
          type: "string",
          description: "The complete text extracted from the letter.",
        },
        summary: {
          type: "string",
          description: "A brief summary of the letter's contents.",
        },
      },
    },
    "savings_account.pdf": {
      type: "object",
      properties: {
        bankDetails: {
          type: "object",
          properties: {
            bankName: {
              type: "string",
            },
            department: {
              type: "string",
            },
            advisor: {
              type: "object",
              properties: {
                name: {
                  type: "string",
                },
                email: {
                  type: "string",
                },
                phone: {
                  type: "string",
                },
              },
              required: ["name", "email", "phone"],
            },
          },
          required: ["bankName", "advisor"],
        },
        accountDetails: {
          type: "object",
          properties: {
            accountNumber: {
              type: "string",
            },
            routingNumber: {
              type: "string",
            },
            accountType: {
              type: "string",
            },
            clearingAccount: {
              type: "string",
            },
            currency: {
              type: "string",
            },
            amount: {
              type: "number",
            },
            interestRate: {
              type: "number",
            },
            period: {
              type: "string",
              enum: ["monthly", "yearly"],
            },
            duration: {
              type: "object",
              properties: {
                startDate: {
                  type: "string",
                },
                endDate: {
                  type: "string",
                },
              },
              required: ["startDate", "endDate"],
            },
          },
          required: [
            "accountNumber",
            "accountType",
            "amount",
            "interestRate",
            "period",
            "duration",
          ],
        },
        accountHolder: {
          type: "object",
          properties: {
            customerID: {
              type: "string",
            },
            name: {
              type: "object",
              properties: {
                firstName: {
                  type: "string",
                },
                lastName: {
                  type: "string",
                },
              },
              required: ["firstName", "lastName"],
            },
            address: {
              type: "object",
              properties: {
                street: {
                  type: "string",
                },
                zipCode: {
                  type: "string",
                },
                city: {
                  type: "string",
                },
              },
              required: ["street", "zipCode", "city"],
            },
            taxDomicile: {
              type: "string",
            },
            occupation: {
              type: "string",
            },
            dateOfBirth: {
              type: "string",
            },
            identification: {
              type: "object",
              properties: {
                type: {
                  type: "string",
                },
                number: {
                  type: "string",
                },
                state: {
                  type: "string",
                },
                expiry: {
                  type: "string",
                },
              },
              required: ["type", "number", "expiry"],
            },
          },
          required: ["customerID", "name", "address", "dateOfBirth", "identification"],
        },
        restrictions: {
          type: "object",
          properties: {
            minimumDeposit: {
              type: "number",
            },
            maximumDeposit: {
              type: "number",
            },
            overdraftInterestRate: {
              type: "number",
            },
            depositCondition: {
              type: "string",
              enum: ["onetime", "daily", "monthly", "yearly"],
            },
          },
          required: ["minimumDeposit", "maximumDeposit", "depositCondition"],
        },
      },
      required: ["bankDetails", "accountDetails", "accountHolder", "restrictions"],
    },
    "bank_transactions.csv": {
      type: "object",
      properties: {
        transactions: {
          type: "array",
          items: {
            type: "object",
            properties: {
              transaction_id: {
                type: "string",
                description: "A unique identifier for the transaction.",
              },
              transaction_type: {
                type: "string",
                enum: ["deposit", "withdrawal", "transfer"],
                description: "The type of transaction.",
              },
              amount: {
                type: "number",
                description: "The amount of money involved in the transaction.",
              },
              transaction_date: {
                type: "string",
                description: "The date and time when the transaction occurred.",
              },
              is_successful: {
                type: "boolean",
                description: "Indicates whether the transaction was successful.",
              },
              account_number: {
                type: "integer",
                description: "The account number associated with the transaction.",
              },
              currency: {
                type: "string",
                enum: ["USD", "EUR", "GBP", "JPY"],
                description: "The currency in which the transaction was made.",
              },
              balance_after_transaction: {
                type: "number",
                description: "The account balance after the transaction was completed.",
              },
              is_recurring: {
                type: "boolean",
                description: "Indicates if the transaction is part of a recurring series.",
              },
              tags: {
                type: "string",
                description: "Any tags associated with the transaction, separated by commas.",
              },
            },
            required: [
              "transaction_id",
              "amount",
              "transaction_date",
              "is_successful",
              "account_number",
              "transaction_type",
              "currency",
              "balance_after_transaction",
              "is_recurring",
            ],
          },
        },
      },
    },
  };

  return JSON.stringify(schemas[option], null, 2);
}

function TestDataTooltip() {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={100}>
        <TooltipTrigger>
          <CircleHelp className="m-1 h-4 w-4 text-indigo-700" />
        </TooltipTrigger>
        <TooltipContent className="w-80 p-0">
          <div className="bg-indigo-100 p-3 rounded-t-md flex items-center">
            <Info className="h-5 w-5 text-indigo-700 mr-2" />
            <h3 className="font-semibold text-indigo-800">Get started with testing data</h3>
          </div>
          <div className="p-3">
            Choose one of the examples to fill in a prompt, the fitting JSON schema as well as a
            file, to test our service.
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
