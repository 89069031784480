import { Copy } from "lucide-react";
import { motion } from "framer-motion";
import { useState } from "react";

export default function CopyToClipboardButton({ onClick }) {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    onClick();

    // Reset the animation state after 300ms
    setTimeout(() => setIsClicked(false), 300);
  };

  return (
    <motion.button
      onClick={handleClick}
      className="flex flex-row justify-center items-center bg-indigo-100 text-xs text-indigo-700 px-2 py-1 rounded-md focus:outline-none"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      animate={isClicked ? { backgroundColor: "#3949AB", color: "#fff" } : {}}
      transition={{ duration: 0.3 }}
    >
      <Copy className="w-3.5 h-3.5 mr-2" />
      Copy
    </motion.button>
  );
}
