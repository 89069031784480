import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import CustomTooltip from "./CustomChartTooltip.js";

export default function CustomLineChart({ header, data, total, xAxisDataKey, yAxisDataKey }) {
  return (
    <div className="flex flex-col h-full shadow sm:rounded-lg text-left">
      <div className="flex flex-row justify-between items-center w-full p-3">
        <div className="font-bold text-lg">{header}</div>
        {total && <div className="text-sm text-gray-500">{`${total} total`}</div>}
      </div>
      {data && (
        <div className="flex-1">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={data}
              margin={{
                top: 20,
                left: -10,
                right: 15,
                bottom: 15,
              }}
            >
              <CartesianGrid vertical={false} stroke="#eeeeee" />
              <XAxis
                dataKey={xAxisDataKey}
                interval={2}
                tick={{ fontSize: 11, dy: 5 }}
                tickFormatter={(tick) => parseInt(tick.split("-")[2])}
              />
              <YAxis tick={{ fontSize: 11, dx: -5 }} />
              <Tooltip cursor={{ fill: "#eeeeee" }} content={<CustomTooltip />} />
              <Area type="monotone" dataKey={yAxisDataKey} stroke="#7986CB" fill="#7986CB" />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
}
