import { Fragment, useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext.js";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  ChartBarIcon,
  DocumentDuplicateIcon,
  BanknotesIcon,
  HomeIcon,
  UserIcon,
  XMarkIcon,
  ArrowRightOnRectangleIcon,
  CommandLineIcon,
} from "@heroicons/react/24/outline";
import JsongenLogo from "../components/icons/JsongenLogo.js";
import Billing from "../components/billing/Billing.js";
import Playground from "../components/playground/Playground.js";
import Dashboard from "../components/dashboard/Dashboard.js";
import Usage from "../components/usage/Usage.js";

const navigationItems = {
  dashboard: { name: "Dashboard", pathTo: "", icon: HomeIcon, current: false },
  playground: {
    name: "Playground",
    pathTo: "playground",
    icon: CommandLineIcon,
    current: false,
  },
  billing: {
    name: "Billing",
    pathTo: "billing",
    icon: BanknotesIcon,
    current: false,
  },
  usage: { name: "Usage", pathTo: "usage", icon: ChartBarIcon, current: false },
  apireference: {
    name: "API Reference",
    pathTo: "/docs/api", // External URL
    external: true, // Add this flag to indicate it's an external link
    icon: DocumentDuplicateIcon,
    current: false,
  },
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DashboardPage() {
  let { selectedItem } = useParams();
  if (!selectedItem) {
    selectedItem = "dashboard";
  }

  const [navigation, setNavigation] = useState(navigationItems);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { session, signOut } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setNavigation((prevNavigation) => {
      const updatedNavigation = { ...prevNavigation };
      Object.keys(updatedNavigation).forEach((key) => {
        updatedNavigation[key].current = key === selectedItem;
      });
      return updatedNavigation;
    });
  }, [selectedItem]);

  const handleSelectNavItem = (item) => {
    setSidebarOpen(false);
    if (!item.external) {
      navigate(`/dashboard/${item.pathTo}`);
    }
  };

  const handleSignOutAction = async (e) => {
    e.preventDefault();
    await signOut();
    setTimeout(() => {
      navigate("/signin");
    }, 1000);
  };

  return (
    <>
      <div className="h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 pb-2">
                    <div className="flex h-16 shrink-0 items-center">
                      <JsongenLogo small braceColor="text-white" />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            {Object.values(navigation).map((item) => (
                              <li key={item.name}>
                                {item.external ? (
                                  <a
                                    href={item.pathTo}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classNames(
                                      item.current
                                        ? "bg-indigo-700 text-white"
                                        : "text-indigo-200 hover:text-white hover:bg-indigo-700",
                                      "w-full group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    )}
                                  >
                                    <item.icon
                                      className={classNames(
                                        item.current
                                          ? "text-white"
                                          : "text-indigo-200 group-hover:text-white",
                                        "h-6 w-6 shrink-0"
                                      )}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </a>
                                ) : (
                                  <Link
                                    to={`/dashboard/${item.pathTo}`}
                                    onClick={() => handleSelectNavItem(item)}
                                    className={classNames(
                                      item.current
                                        ? "bg-indigo-700 text-white"
                                        : "text-indigo-200 hover:text-white hover:bg-indigo-700",
                                      "w-full group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    )}
                                  >
                                    <item.icon
                                      className={classNames(
                                        item.current
                                          ? "text-white"
                                          : "text-indigo-200 group-hover:text-white",
                                        "h-6 w-6 shrink-0"
                                      )}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </Link>
                                )}
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="-mx-6 mt-auto">
                          <button
                            onClick={handleSignOutAction}
                            className="flex items-center w-full gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-indigo-700"
                          >
                            <ArrowRightOnRectangleIcon className="h-5 w-5"></ArrowRightOnRectangleIcon>
                            <span aria-hidden="true">Sign out</span>
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6">
            <div className="flex h-16 shrink-0 items-center">
              <JsongenLogo small braceColor="text-white" />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {Object.values(navigation).map((item) => (
                      <li key={item.name}>
                        {item.external ? (
                          <a
                            href={item.pathTo}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classNames(
                              item.current
                                ? "bg-indigo-700 text-white"
                                : "text-indigo-200 hover:text-white hover:bg-indigo-700",
                              "w-full group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.current
                                  ? "text-white"
                                  : "text-indigo-200 group-hover:text-white",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ) : (
                          <Link
                            to={`/dashboard/${item.pathTo}`}
                            onClick={() => handleSelectNavItem(item)}
                            className={classNames(
                              item.current
                                ? "bg-indigo-700 text-white"
                                : "text-indigo-200 hover:text-white hover:bg-indigo-700",
                              "w-full group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.current
                                  ? "text-white"
                                  : "text-indigo-200 group-hover:text-white",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="-mx-6 mt-auto">
                  <div className="flex items-center w-full gap-x-2 px-6 py-3 text-sm font-semibold leading-6 text-white">
                    <UserIcon className="h-5 w-5"></UserIcon>
                    <p>{session.user.email}</p>
                  </div>
                  <button
                    onClick={handleSignOutAction}
                    className="flex items-center w-full gap-x-2 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-indigo-700"
                  >
                    <ArrowRightOnRectangleIcon className="h-5 w-5"></ArrowRightOnRectangleIcon>
                    <span aria-hidden="true">Sign out</span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-indigo-600 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-indigo-200 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        {selectedItem === "dashboard" && <Dashboard />}
        {selectedItem === "playground" && <Playground />}
        {selectedItem === "billing" && <Billing />}
        {selectedItem === "usage" && <Usage />}
      </div>
    </>
  );
}
