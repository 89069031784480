import { Link } from 'react-router-dom';
import { forwardRef } from 'react';

const Input = forwardRef(({ children, id, labelClasses, inputClasses, helperLinkTo, helperLinkText, defaultValue, ...props }, ref) => {
  return (
    <div>
      <div className="flex items-center justify-between">
        <label 
          htmlFor={id} 
          className={labelClasses}
        >
          {children}
        </label>
        
        {
          helperLinkTo && helperLinkText &&
            <div className="text-sm">
              <Link 
                to={helperLinkTo}
                className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                  {helperLinkText}
              </Link>
            </div>
        }
      </div>
     

      <input
        id={id}
        ref={ref}
        className={inputClasses}
        value={defaultValue}
        {...props}
      >
      </input>
    </div>
  );
});

export default Input;