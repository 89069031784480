import { useState, useEffect } from "react";
import { useStripe } from "../../../contexts/StripeContext.js";
import { useAuth } from "../../../contexts/AuthContext.js";
import { formatNumber } from "../../../utils/numbers.js";
import PaymentMethodsDropdownItem from "../payments/PaymentMethodsDropdownItem.js";
import { createPaymentIntent, confirmPaymentIntent } from "../../../services/paymentservice.js";

export default function CreditsDialogCheckout({ onSuccess, onError, onCancel }) {
  const { session } = useAuth();
  const { amount, customerId, addToCreditBalance, paymentMethodId, paymentMethods } = useStripe();

  const [paymentIntent, setPaymentIntent] = useState(null);
  const [paymentIntentLoading, setPaymentIntentLoading] = useState(true);

  useEffect(() => {
    const createPaymentIntentFn = async () => {
      try {
        const data = await createPaymentIntent(
          session.access_token,
          customerId,
          paymentMethodId,
          amount
        );
        setPaymentIntent(data);
        setPaymentIntentLoading(false);
      } catch (error) {
        console.error(error);
        onError();
      }
    };

    if (!paymentIntent) {
      createPaymentIntentFn();
    }
  }, [session, amount, customerId, paymentMethodId, paymentIntent, onError]);

  const confirmPaymentIntentFn = async (paymentIntent) => {
    try {
      const data = await confirmPaymentIntent(
        session.access_token,
        paymentIntent.paymentIntentId,
        paymentIntent.paymentMethodId
      );

      if (data && data.status === "succeeded") {
        addToCreditBalance(data.amount);
        onSuccess();
      } else {
        throw new Error("Confirming payment intent was not successful", data);
      }
    } catch (error) {
      console.error(error);
      onError();
    }
  };

  return (
    <>
      <h3 id="payment-heading" className="mb-2 text-left text-lg font-medium text-gray-900">
        Confirm your payment
      </h3>
      <div>
        <dl className="mt-10 space-y-6 text-sm font-medium text-gray-500">
          <div className="flex justify-between">
            <dt>Service credits</dt>
            {paymentIntentLoading ? (
              <div role="status" className="w-10 mr-3 animate-pulse">
                <div className="h-4 bg-gray-300 rounded-md dark:bg-gray-700 w-14"></div>
              </div>
            ) : (
              <dd className="text-gray-900">
                {paymentIntent && `$${formatNumber(paymentIntent.amount)}`}
              </dd>
            )}
          </div>
          <div className="flex justify-between">
            <dt>Taxes</dt>
            {paymentIntentLoading ? (
              <div role="status" className="w-10 mr-3 animate-pulse">
                <div className="h-4 bg-gray-300 rounded-md dark:bg-gray-700 w-14"></div>
              </div>
            ) : (
              <dd className="text-gray-900">
                {paymentIntent && `$${formatNumber(paymentIntent.taxes)}`}
              </dd>
            )}
          </div>
          <div className="flex justify-between border-t border-gray-200 pt-6 text-gray-900">
            <dt className="text-base">Total</dt>
            {paymentIntentLoading ? (
              <div role="status" className="w-12 mr-3 animate-pulse">
                <div className="h-6 bg-gray-300 rounded-md dark:bg-gray-700 w-16"></div>
              </div>
            ) : (
              <dd className="text-base">
                {paymentIntent && `$${formatNumber(paymentIntent.total)}`}
              </dd>
            )}
          </div>
        </dl>
      </div>
      <label className="mt-4 text-left block text-sm font-medium text-gray-500">
        Payment method
      </label>
      <div
        disabled={true}
        className={`mt-2 flex items-center w-full gap-x-1.5 rounded-md px-3 py-2 font-semibold shadow-sm ring-1 ring-inset 
          ${
            true
              ? "cursor-not-allowed ring-gray-200"
              : "bg-white text-gray-900 hover:bg-gray-50 ring-gray-300"
          }`}
      >
        <span className="w-full flex items-center gap-x-1.5">
          <PaymentMethodsDropdownItem
            paymentMethod={paymentMethods.find((method) => paymentMethodId === method?.id)}
          />
        </span>
      </div>
      <p className="mt-2 text-left text-xs text-gray-500">
        By continuing you agree to our{" "}
        <a
          href="https://www.jsongen.com/terms"
          target="_blank"
          rel="noopener noreferrer"
          className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
        >
          service credit terms.
        </a>{" "}
        Paid credits are non-refundable.
      </p>
      <div className="flex justify-end pt-6">
        <button
          type="button"
          onClick={onCancel}
          className="mr-2 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none"
        >
          Back
        </button>
        <button
          type="button"
          onClick={() => confirmPaymentIntentFn(paymentIntent)}
          className="rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
        >
          Confirm payment
        </button>
      </div>
    </>
  );
}
