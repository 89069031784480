import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentDetailsForm from "./PaymentDetailsForm.js";

export default function PaymentDetailsFormStripe({ onSuccess }) {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_API_KEY));
  }, []);

  return (
    <div>
      {stripePromise ? (
        <Elements stripe={stripePromise} options={{ locale: "en" }}>
          <PaymentDetailsForm onSuccess={onSuccess} />
        </Elements>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}
