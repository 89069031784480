import React from "react";

export default function CustomTooltip({ active, payload, label }) {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip bg-white border border-gray-300 p-2 rounded-lg shadow-md text-gray-800">
        <p className="label m-0 font-bold text-sm">{`${transformDateLabel(label)}`}</p>
        {payload.map((entry, index) => (
          <div key={`item-${index}`} className="intro my-1 text-xs">
            <div className="flex flex-row items-center gap-2 w-full">
              <div className="w-2 h-2" style={{ backgroundColor: entry.fill }}></div>
              <div>{`${transformPropertyName(entry.name)}`}</div>
              <div className="ml-auto">{transformValue(entry.value)}</div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return null;
}

function transformDateLabel(dateLabel) {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateLabel)) {
    return dateLabel;
  }

  const [year, month, day] = dateLabel.split("-");
  const formattedDate = `${day}.${month}.${year}`;
  return formattedDate;
}

function transformPropertyName(propertyName) {
  // Replace underscores with spaces
  let result = propertyName.replace(/_/g, " ");
  // Convert the result to title case
  result = result
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return result;
}

function transformValue(value) {
  if (typeof value === "number" && !Number.isInteger(value)) {
    return value.toFixed(2); // Return the number formatted to two decimal places
  }
  value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Pretty print number
  return value; // If the value is not a float, return it as is
}
