import { useState } from 'react';
import ForgotPasswordForm from '../components/auth/ForgotPasswordForm.js';
import SentMailMessage from '../components/auth/SentMailMessage.js';

export default function ForgotPasswordPage() {
  const [ formMessageData, setFormMessageData ] = useState(undefined);
  
  return (
    formMessageData ? 
      <SentMailMessage 
        headerText="Check your email"
        mainText="We just sent a password reset link to"
        linkText="Go to Login"
        linkTo="/signin"
        emailTo={formMessageData} 
      /> 
      : 
      <ForgotPasswordForm handleFormMessage={setFormMessageData}/>
  )
}