import React from 'react';
import * as Slider from '@radix-ui/react-slider';
import './slider-styles.css';

const SliderComponent = ({ value, onValueChange }) => {
  return (
    <Slider.Root 
      value={value && [value]}
          className="SliderRoot" 
          defaultValue={!value && [50]} 
          min={1} 
          max={1000} 
          step={1} 
          onValueChange={(val) => onValueChange(val[0])}
      >
        <Slider.Track className="SliderTrack">
          <Slider.Range className="SliderRange" />
        </Slider.Track>
        <Slider.Thumb className="SliderThumb" aria-label="Volume" />
      </Slider.Root>
  );
};

export default SliderComponent;