import { formatNumber } from "../../../utils/numbers.js";
import { formatDateTime } from "../../../utils/dates.js";
import LoadingPlaceholder from "../../ui/LoadingPlaceholder.js";

const statuses = {
  succeeded: 'text-green-700 bg-green-50 ring-green-600/20',
  Withdraw: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  Overdue: 'text-red-700 bg-red-50 ring-red-600/10',
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function BillingHistoryItem({ receiptNumber, receiptUrl, amount, currency, created, status }) {
  return (
    <tr key={created}>
      {/* On small screens display columns blow each other*/}
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
        {receiptNumber ? receiptNumber : "Not available"}
        <dl className="font-normal lg:hidden">
          <dt className="sr-only">Title</dt>
          <dd className="mt-1 truncate text-gray-700">
            {amount && currency ? `${formatNumber(amount)} ${currency.toUpperCase()}` : <LoadingPlaceholder width={20} />}
          </dd>
          <dt className="sr-only sm:hidden">Created</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            {created ? `${formatDateTime(created)}` : <LoadingPlaceholder width={20} />}
          </dd>
        </dl>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
        {amount && currency ? `${formatNumber(amount)} ${currency.toUpperCase()}` : <LoadingPlaceholder width={20} height={6} />}
      </td>

      {/* On big screens display columns next to each other*/}
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {created ? `${formatDateTime(created)}` : <LoadingPlaceholder width={20} />}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500">
        {
          status ? 
            <div className="flex items-start gap-x-3">
              <div
                className={classNames(
                  statuses[status],
                  'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                )}
              >
                {status && status?.charAt(0).toUpperCase() + status?.slice(1)}
              </div>
            </div>
            :
            <LoadingPlaceholder width={20} />
        }
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
        <a
          href={receiptUrl}
          className="text-indigo-600 hover:text-indigo-900"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.preventDefault();
            window.open(receiptUrl, '_blank');
          }}
        >
          View<span className="sr-only">, {receiptUrl}</span>
        </a>
      </td>
    </tr>
  );
}