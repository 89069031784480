import { useEffect, useState } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { getFileUrl } from "../../services/storageservice";

export default function SwaggerUIComponent() {
  const [swaggerUrl, setSwaggerUrl] = useState(null);

  useEffect(() => {
    document.title = "jsongen - API Docs";
  });

  useEffect(() => {
    const getSwaggerUrl = async () => {
      const file = await getFileUrl("swagger.json");
      setSwaggerUrl(file?.data?.publicUrl);
    };
    getSwaggerUrl();
  }, []);

  return (
    swaggerUrl && (
      <div className="text-left">
        <SwaggerUI url={swaggerUrl} docExpansion="full" />
      </div>
    )
  );
}
