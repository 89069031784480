import { useRef } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useAuth } from "../../../contexts/AuthContext.js";
import { useStripe as useStripeContext } from "../../../contexts/StripeContext.js";

export default function PaymentDetailsForm({ onSuccess }) {
  const { session } = useAuth();
  const { customerId, updatePaymentMethodId, updatePaymentMethods } = useStripeContext();

  const stripe = useStripe();
  const elements = useElements();

  const cardNameRef = useRef(null);
  const addressRef = useRef(null);
  const cityRef = useRef(null);
  const postalCodeRef = useRef(null);

  // Function to handle adding payment details
  const handleAddPaymentDetails = async () => {
    // Collect values from refs
    const cardName = cardNameRef.current.value;
    const address = addressRef.current.value;
    const city = cityRef.current.value;
    const postalCode = postalCodeRef.current.value;

    const stripeResult = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          email: session?.user?.email,
          name: cardName,
          address: {
            city: city,
            country: "US",
            line1: address,
            postal_code: postalCode,
          },
        },
      },
    });

    const paymentDetails = {
      customerId: customerId,
      paymentMethodId: stripeResult.paymentMethod.id,
    };

    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/customers/payment_methods/attach`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.access_token}`,
      },
      body: JSON.stringify(paymentDetails),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.id) {
          updatePaymentMethodId(result.id);
          updatePaymentMethods(result.paymentMethods);
          onSuccess();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="flex items-start">
      <form className="w-full">
        <div className="w-full">
          <div>
            <h3 id="payment-heading" className="mb-2 text-left text-lg font-medium text-gray-900">
              Payment details
            </h3>

            <div className="mb-4 col-span-3 sm:col-span-4">
              <label
                htmlFor="card-name"
                className="text-left block text-sm font-medium text-gray-700"
              >
                Name on card
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="card-name"
                  name="card-name"
                  autoComplete="cc-name"
                  className="block w-full max-h-[38px] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  ref={cardNameRef}
                />
              </div>
            </div>
            <label
              htmlFor="card-name"
              className="text-left block text-sm font-medium text-gray-700"
            >
              Card information
            </label>
            <div className="mt-1 border h-[38px] px-2 py-2.5 border-gray-300 shadow-sm rounded-md">
              <CardElement
                options={{
                  hidePostalCode: true,
                }}
              />
            </div>
          </div>

          <div className="mt-6">
            <h3 id="billing-heading" className="text-left text-lg font-medium text-gray-900">
              Billing address
            </h3>

            <div className="mt-2 grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <label
                  htmlFor="address"
                  className="text-left block text-sm font-medium text-gray-700"
                >
                  Address
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="address"
                    name="address"
                    autoComplete="street-address"
                    className="block w-full max-h-[38px] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    ref={addressRef}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="city" className="text-left block text-sm font-medium text-gray-700">
                  City
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="city"
                    name="city"
                    autoComplete="address-level2"
                    className="block w-full max-h-[38px] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    ref={cityRef}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="postal-code"
                  className="text-left block text-sm font-medium text-gray-700"
                >
                  Postal code
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="postal-code"
                    name="postal-code"
                    autoComplete="postal-code"
                    className="block w-full max-h-[38px] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    ref={postalCodeRef}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end pt-6">
            <button
              type="button"
              onClick={onSuccess}
              className="mr-2 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-800 focus:outline-none"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleAddPaymentDetails}
              className="rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
            >
              Add payment details
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
