import { useState, useEffect } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shadcn/components/ui/select.jsx";

const testingOptions = [
  {
    label: "JSON Result",
    value: "json",
  },
  {
    label: "cURL",
    value: "curl",
  },
  {
    label: "JavaScript",
    value: "javascript",
  },
  {
    label: "Python",
    value: "python",
  },
];

export default function Dropdown({ active, onChange }) {
  const [activeOption, setActiveOption] = useState(active);

  useEffect(() => {
    setActiveOption(active);
  }, [active]);

  return (
    <Select
      onValueChange={(val) => {
        setActiveOption(val);
        onChange(val);
      }}
      value={activeOption}
    >
      <SelectTrigger className="w-full bg-white text-indigo-700 border-indigo-300 focus:ring-0 focus:ring-offset-0">
        <SelectValue placeholder="JSON Result" />
      </SelectTrigger>

      <SelectContent>
        {testingOptions.map((option) => (
          <SelectItem key={option.value} value={option.value}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
