import { createContext, useState, useContext, useEffect } from "react";
import { useAuth } from "./AuthContext.js";
import { fetchCredits, fetchCustomer, fetchPaymentMethods } from "../services/customerservice.js";

const StripeContext = createContext();

export function useStripe() {
  return useContext(StripeContext);
}

export function StripeProvider({ children }) {
  const { session } = useAuth();

  const [amount, setAmount] = useState(50);
  const [customerId, setCustomerId] = useState(null);

  const [creditBalance, setCreditBalance] = useState(null);
  const [creditBalanceLoading, setCreditBalanceLoading] = useState(true);
  const [creditBalanceError, setCreditBalanceError] = useState(false);

  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodsLoading, setPaymentMethodsLoading] = useState(true);
  const [paymentMethodsError, setPaymentMethodsError] = useState(false);

  const updateAmount = (newAmount) => {
    setAmount(newAmount);
  };

  const updateCustomerId = (newCustomerId) => {
    setCustomerId(newCustomerId);
  };

  const addToCreditBalance = (amount) => {
    setCreditBalance((prev) => prev + amount);
  };

  const updatePaymentMethodId = (newPaymentMethodId) => {
    setPaymentMethodId(newPaymentMethodId);
  };

  const updatePaymentMethods = (newPaymentMethods) => {
    setPaymentMethods(newPaymentMethods);
  };

  useEffect(() => {
    const getCredits = async () => {
      try {
        const data = await fetchCredits(session.access_token);
        setCreditBalance(data.balance);
        setCreditBalanceLoading(false);
      } catch (error) {
        setCreditBalanceError(true);
      }
    };

    if (!creditBalance || creditBalanceLoading) {
      getCredits();
    }
  });

  useEffect(() => {
    const getCustomerId = async () => {
      try {
        const data = await fetchCustomer(session.access_token);
        updateCustomerId(data.customerId);
      } catch (error) {
        setPaymentMethodsError(true);
      }
    };

    if (!customerId) {
      getCustomerId();
    }
  }, [session, customerId]);

  useEffect(() => {
    const getPaymentMethods = async () => {
      try {
        const data = await fetchPaymentMethods(session.access_token, customerId);
        updatePaymentMethodId(data.defaultPaymentMethodId ?? data.paymentMethods[0]?.id ?? null);
        updatePaymentMethods(data.paymentMethods);
        setPaymentMethodsLoading(false);
      } catch (error) {
        setPaymentMethodsError(true);
      }
    };

    if (customerId) {
      getPaymentMethods();
    }
  }, [session, customerId]);

  return (
    <StripeContext.Provider
      value={{
        amount,
        customerId,
        creditBalance,
        creditBalanceLoading,
        creditBalanceError,
        addToCreditBalance,
        paymentMethodId,
        paymentMethods,
        paymentMethodsLoading,
        paymentMethodsError,
        updateAmount,
        updateCustomerId,
        updatePaymentMethodId,
        updatePaymentMethods,
      }}
    >
      {children}
    </StripeContext.Provider>
  );
}
