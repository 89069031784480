import { createContext, useContext, useState, useEffect } from "react";
import { supabase } from "../supabase/client.js";
import { createCustomer } from "../services/customerservice.js";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState(null);

  function handleSetSession(session) {
    setSession(session);
    setLoading(false);
  }

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN" || event === "TOKEN_REFRESHED" || event === "PASSWORD_RECOVERY") {
        handleSetSession(session ?? null);
      } else if (event === "SIGNED_OUT") {
        handleSetSession(null);
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const signIn = async ({ email, password }) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      });

      return { data, error };
    } catch (error) {
      return { data: null, error };
    }
  };

  const signInWithOAuth = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: process.env.REACT_APP_CLIENT_BASE_URL,
        },
      });

      createCustomer(data.user?.id, data.user?.email);
      return { data, error };
    } catch (error) {
      return { data: null, error };
    }
  };

  const signUp = async ({ email, password, options }) => {
    try {
      const { data, error } = await supabase.auth.signUp({
        email: email,
        password: password,
        options: options,
      });

      createCustomer(data.user?.id, data.user?.email);
      return { data, error };
    } catch (error) {
      return { data: null, error };
    }
  };

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    return error;
  };

  const sendResetPasswordMail = async ({ email, redirectTo }) => {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: redirectTo,
    });
    return error;
  };

  const updatePassword = async (password) => {
    const { error } = await supabase.auth.updateUser({
      password: password,
    });
    return error;
  };

  const value = {
    loading,
    session,
    signIn,
    signInWithOAuth,
    signUp,
    signOut,
    sendResetPasswordMail,
    updatePassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
