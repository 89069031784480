import { useState, forwardRef } from "react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { XCircleIcon } from "@heroicons/react/20/solid";
import Input from "../ui/Input.js";

const ChoosePasswordInput = forwardRef(
  ({ children, labelClasses, inputClasses, updateValidState }, ref) => {
    const [requirementsMet, setRequirementsMet] = useState({
      length: false,
      lowercase: false,
      uppercase: false,
      digit: false,
    });

    const [inputTouched, setInputTouched] = useState(false);

    const checkRequirements = (e) => {
      // Only render list of requirements when user initially touched the input
      setInputTouched(true);
      const value = e.target.value;

      // Check requirements
      const lengthMet = value.length >= 8;
      const lowercaseMet = /[a-z]/.test(value);
      const uppercaseMet = /[A-Z]/.test(value);
      const digitMet = /[0-9]/.test(value);

      updateValidState(lengthMet && lowercaseMet && uppercaseMet && digitMet);

      setRequirementsMet({
        length: lengthMet,
        lowercase: lowercaseMet,
        uppercase: uppercaseMet,
        digit: digitMet,
      });
    };

    // Array of requirements for li elements
    const requirements = [
      { met: requirementsMet.length, text: "Minimum 8 characters" },
      { met: requirementsMet.lowercase, text: "At least one lowercase letter" },
      { met: requirementsMet.uppercase, text: "At least one uppercase letter" },
      { met: requirementsMet.digit, text: "At least one digit" },
    ];

    return (
      <div>
        <Input
          id="password"
          name="password"
          type="password"
          autoComplete="current-password"
          required
          ref={ref}
          onChange={checkRequirements}
          labelClasses={labelClasses}
          inputClasses={inputClasses}
        >
          {children}
        </Input>
        {inputTouched && (
          <ul className="flex-col items-center mt-2">
            {requirements.map((requirement, index) => (
              <li key={index} className="flex items-center text-sm">
                {requirement.met ? (
                  <CheckCircleIcon
                    className="mr-1 h-4 w-4 text-green-400"
                    aria-hidden="true"
                  />
                ) : (
                  <XCircleIcon
                    className="mr-1 h-4 w-4 text-red-400"
                    aria-hidden="true"
                  />
                )}
                {requirement.text}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
);

export default ChoosePasswordInput;
