import React from 'react';
import VisaIcon from './VisaIcon.js';
import MastercardIcon from './MastercardIcon.js';
import AmexIcon from './AmexIcon.js';
import DinersIcon from './DinersIcon.js';
import OtherCardIcon from './OtherCardIcon.js';

function PaymentMethodIcon({ brand }) {
  switch (brand) {
    case "visa":
      return <VisaIcon />;
    case "mastercard":
      return <MastercardIcon />;
    case "amex":
      return <AmexIcon />;
    case "diners":
      return <DinersIcon />;
    default:
      return <OtherCardIcon />;
  }
}

export default PaymentMethodIcon;
