import { Link } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/20/solid";

const includedFeatures = [
  "Full API Access",
  "Transparent Usage Overview",
  "E-Mail Customer Support",
];

export default function Pricing() {
  return (
    <div className="bg-white pb-24 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Simple usage-based pricing
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            With our usage-based pricing, you only pay for what you use — no hidden fees, no
            surprises. Costs are calculated in tokens, ensuring complete transparency.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">API Credits</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Purchase API credits in advance and use them as needed. You have full control over how
              many credits to buy and can utilize them with your API key for seamless requests.
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                What’s included
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul className="mt-8 flex flex-col sm:flex-row gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600 mb-4">API Token Pricing</p>
                <div className="grid grid-cols-2 gap-x-3 gap-y-2">
                  <div className="text-sm text-left self-end">Prompt Tokens</div>
                  <div className="text-right self-end">
                    <span className="text-xl font-bold tracking-tight text-gray-900">$0.05</span>
                    <span className="text-xs text-gray-600"> / 1K tokens</span>
                  </div>
                  <div className="text-sm text-left self-end">Completion Tokens</div>
                  <div className="text-right self-end">
                    <span className="text-xl font-bold tracking-tight text-gray-900">$0.15</span>
                    <span className="text-xs text-gray-600"> / 1K tokens</span>
                  </div>
                </div>
                <Link
                  className="mt-6 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  to="/signup"
                >
                  Get access
                </Link>
                <p className="mt-4 text-xs leading-5 text-gray-600">
                  Invoices are available in the Dashboard.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
