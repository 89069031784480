import React, { useCallback } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { EditorView } from "@codemirror/view";
import { syntaxHighlighting, HighlightStyle } from "@codemirror/language";
import { tags } from "@lezer/highlight";

const customHighlightStyle = HighlightStyle.define([
  {
    tag: tags.string,
    color: "green",
  },
  {
    tag: tags.number,
    color: "blue",
  },
  {
    tag: tags.bool,
    color: "purple",
  },
  {
    tag: tags.keyword,
    color: "indigo",
  },
  {
    tag: tags.function(tags.variableName),
    color: "blue",
  },
  {
    tag: tags.comment,
    fontStyle: "italic",
    color: "gray",
  },
  {
    tag: tags.className,
    color: "darkorange",
  },
  {
    tag: tags.variableName,
    color: "black",
  },
]);

const customTheme = EditorView.theme(
  {
    "&": {
      fontSize: "14px",
      fontFamily: "'Consolas', 'Monaco', 'Andale Mono', 'Ubuntu Mono', monospace",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    ".cm-scroller": {
      flexGrow: 1,
      overflow: "auto",
    },
    ".cm-content": {
      fontFamily: "'Consolas', 'Monaco', 'Andale Mono', 'Ubuntu Mono', monospace",
    },
    ".cm-gutters": {
      display: "none",
    },
    ".cm-gutterElement": {
      display: "none",
    },
    ".cm-activeLine": {
      backgroundColor: "transparent",
    },
    ".cm-activeLineGutter": {
      backgroundColor: "transparent",
    },
    ".cm-selectionMatch": {
      backgroundColor: "transparent",
    },
    "&:not(.cm-focused) .cm-selectionBackground": {
      backgroundColor: "transparent",
    },
    "&.cm-focused": {
      outline: "none",
    },
  },
  { dark: false }
);

const CodeEditor = ({ value, readOnly, onChange }) => {
  const preventDragAndDrop = EditorView.domEventHandlers({
    dragstart: (event) => {
      event.preventDefault();
    },
    drop: (event) => {
      event.preventDefault();
    },
  });

  const handleChange = useCallback(
    (value, viewUpdate) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <div className="h-full overflow-y-auto">
      <CodeMirror
        value={value}
        extensions={[
          json(),
          customTheme,
          preventDragAndDrop,
          syntaxHighlighting(customHighlightStyle),
          EditorView.lineWrapping,
          readOnly ? EditorView.editable.of(false) : [],
        ]}
        onChange={handleChange}
        minHeight="100%"
        style={{
          padding: "4px",
          borderRadius: "6px",
          height: "100%",
        }}
      />
    </div>
  );
};

export default CodeEditor;
