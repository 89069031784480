import React from 'react';

const CustomSVG = () => (
  <svg
    className="h-8 mr-2 w-8 sm:h-7 sm:flex-shrink-0"
    viewBox="0 0 750 471"
    version="1.1"
  >
    <defs></defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="default" fillRule="nonzero">
        <rect
          id="Rectangle-1"
          fill="#3949AB"
          x="0"
          y="0"
          width="750"
          height="471"
          rx="90"
        ></rect>
        <rect
          id="rect4183"
          fill="#9D9400"
          x="48.710224"
          y="153.57616"
          width="110.32414"
          height="99.678818"
          rx="20.322868"
        ></rect>
        <g id="text4185" transform="translate(51.000000, 279.000000)" fill="#FFFFFF">
        <polygon
            id="path4219"
            points="27.102474 31.37012 27.102474 27.57512 21.984974 27.57512 21.984974 16.30512 17.787474 16.30512 17.384974 27.57512 5.942474 27.57512 16.522474 2.50512 12.554974 0.78012 0.882474 27.92012 0.882474 31.37012 17.327474 31.37012 17.327474 41.03012 21.984974 41.03012 21.984974 31.37012 27.102474 31.37012"
          ></polygon>
          <path
            d="M46.687513,0.78012 C42.662513,0.78012 38.810013,2.16012 35.475013,5.38012 L38.062513,8.31262 C40.707513,5.84012 43.065013,4.63262 46.457513,4.63262 C50.65501,4.63262 53.99001,6.99012 53.99001,11.36012 C53.99001,16.13262 50.25251,18.31762 46.457513,18.31762 L44.100013,18.31762 L43.525013,22.11262 L46.860013,22.11262 C51.51751,22.11262 55.08251,23.95262 55.08251,29.64512 C55.08251,34.59012 51.80501,37.75262 46.227513,37.75262 C43.007513,37.75262 39.672513,36.43012 37.430013,33.78512 L34.210013,36.43012 C37.200013,40.11012 41.915013,41.66262 46.342513,41.66262 C54.50751,41.66262 60.08501,36.48762 60.08501,29.64512 C60.08501,23.49262 55.71501,20.27262 51.05751,19.92762 C55.25501,19.12262 58.82001,15.50012 58.82001,10.72762 C58.82001,5.32262 54.10501,0.78012 46.687513,0.78012 Z"
            id="path4221"
          ></path>
          <path
            d="M81.16505,0.78012 C75.81755,0.78012 72.25255,2.67762 69.09005,6.47262 L72.42505,9.00262 C74.95505,6.07012 77.08255,4.80512 80.99255,4.80512 C85.42005,4.80512 88.06505,7.56512 88.06505,11.99262 C88.06505,18.49012 84.84505,22.80262 69.89505,37.12012 L69.89505,41.03012 L93.41255,41.03012 L93.98755,36.94762 L75.35755,36.94762 C88.41005,25.04512 93.06755,19.12262 93.06755,11.87762 C93.06755,5.55262 88.64005,0.78012 81.16505,0.78012 Z"
            id="path4223"
          ></path>
          <polygon
            id="path4225"
            points="129.90259 37.12012 121.62259 37.12012 121.62259 1.47012 117.42509 1.47012 105.69509 8.71512 107.76509 12.10762 116.85009 6.64512 116.85009 37.12012 107.07509 37.12012 107.07509 41.03012 129.90259 41.03012 129.90259 37.12012"
          ></polygon>
          <polygon
            id="path4227"
            points="199.49017 31.37012 199.49017 27.57512 194.37267 27.57512 194.37267 16.30512 190.17517 16.30512 189.77267 27.57512 178.33017 27.57512 188.91017 2.50512 184.94267 0.78012 173.27017 27.92012 173.27017 31.37012 189.71517 31.37012 189.71517 41.03012 194.37267 41.03012 194.37267 31.37012 199.49017 31.37012"
          ></polygon>
          <path
            d="M219.07521,0.78012 C215.05021,0.78012 211.19771,2.16012 207.86271,5.38012 L210.45021,8.31262 C213.09521,5.84012 215.45271,4.63262 218.84521,4.63262 C223.04271,4.63262 226.37771,6.99012 226.37771,11.36012 C226.37771,16.13262 222.64021,18.31762 218.84521,18.31762 L216.48771,18.31762 L215.91271,22.11262 L219.24771,22.11262 C223.90521,22.11262 227.47021,23.95262 227.47021,29.64512 C227.47021,34.59012 224.19271,37.75262 218.61521,37.75262 C215.39521,37.75262 212.06021,36.43012 209.81771,33.78512 L206.59771,36.43012 C209.58771,40.11012 214.30271,41.66262 218.73021,41.66262 C226.89521,41.66262 232.47271,36.48762 232.47271,29.64512 C232.47271,23.49262 228.10271,20.27262 223.44521,19.92762 C227.64271,19.12262 231.20771,15.50012 231.20771,10.72762 C231.20771,5.32262 226.49271,0.78012 219.07521,0.78012 Z"
            id="path4229"
          ></path>
          <path
            d="M253.55275,0.78012 C248.20525,0.78012 244.64025,2.67762 241.47775,6.47262 L244.81275,9.00262 C247.34275,6.07012 249.47025,4.80512 253.38025,4.80512 C257.80775,4.80512 260.45275,7.56512 260.45275,11.99262 C260.45275,18.49012 257.23275,22.80262 242.28275,37.12012 L242.28275,41.03012 L265.80025,41.03012 L266.37525,36.94762 L247.74525,36.94762 C260.79775,25.04512 265.45525,19.12262 265.45525,11.87762 C265.45525,5.55262 261.02775,0.78012 253.55275,0.78012 Z"
            id="path4231"
          ></path>
          <polygon
            id="path4233"
            points="308.29029 31.37012 308.29029 27.57512 303.17279 27.57512 303.17279 16.30512 298.97529 16.30512 298.57279 27.57512 287.13029 27.57512 297.71029 2.50512 293.74279 0.78012 282.07029 27.92012 282.07029 31.37012 298.51529 31.37012 298.51529 41.03012 303.17279 41.03012 303.17279 31.37012 308.29029 31.37012"
          ></polygon>
          <path
            d="M329.20033,0.78012 C325.17533,0.78012 321.32283,2.16012 317.98783,5.38012 L320.57533,8.31262 C323.22033,5.84012 325.57783,4.63262 328.97033,4.63262 C333.16783,4.63262 336.50283,6.99012 336.50283,11.36012 C336.50283,16.13262 332.76533,18.31762 328.97033,18.31762 L326.61283,18.31762 L326.03783,22.11262 L329.37283,22.11262 C334.03033,22.11262 337.59533,23.95262 337.59533,29.64512 C337.59533,34.59012 334.31783,37.75262 328.74033,37.75262 C325.52033,37.75262 322.18533,36.43012 319.94283,33.78512 L316.72283,36.43012 C319.71283,40.11012 324.42783,41.66262 328.85533,41.66262 C337.02033,41.66262 342.59783,36.48762 342.59783,29.64512 C342.59783,23.49262 338.22783,20.27262 333.57033,19.92762 C337.76783,19.12262 341.33283,15.50012 341.33283,10.72762 C341.33283,5.32262 336.61783,0.78012 329.20033,0.78012 Z"
            id="path4235"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default CustomSVG;
