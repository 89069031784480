import { memo, useState, useCallback, useMemo, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext.js";
import { useToastMessages } from "../ui/useToastMessages.js";
import { Toaster } from "../../shadcn/components/ui/toaster.jsx";
import { getFile } from "../../services/storageservice.js";
import { generateResponse } from "../../services/generateservice.js";
import { getPreviewAreaContent } from "./playgroundUtils.js";
import PlaygroundTestData, { getTestPrompt, getTestSchema } from "./PlaygroundTestData.js";
import { fetchApiKey, fetchApiKeyShort, updateApiKey } from "../../services/customerservice.js";
import CodeEditor from "./CodeEditor.js";
import CodeOutput from "./CodeOutput.js";
import Dropdown from "../ui/Dropdown.js";
import Dropzone from "../ui/Dropzone.js";
import ResizeableSplitView from "../ui/ResizeableSplitView.js";
import CopyToClipboardButton from "../ui/CopyToClipboardButton.js";
import LoadingBar from "../ui/LoadingBar.js";
import { motion } from "framer-motion";
import { Loader2, RefreshCw, Trash2, ArrowLeftFromLine, ArrowRightFromLine } from "lucide-react";

export default function Playground() {
  const { session } = useAuth();
  const { showToast } = useToastMessages();

  const [prompt, setPrompt] = useState("");
  const [schema, setSchema] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [attachmentError, setAttachmentError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseJson, setResponseJson] = useState("");
  const [resetTrigger, setResetTrigger] = useState(false);
  const [activePreviewArea, setActivePreviewArea] = useState("json");
  const [activeChild, setActiveChild] = useState(1);
  const [shortApiKey, setShortApiKey] = useState("••••••••");

  const getApiKeyShort = useCallback(async () => {
    try {
      const data = await fetchApiKeyShort(session.access_token);
      const shortKey = data.shortApiKey;
      setShortApiKey(shortKey + " ••••");
    } catch (error) {
      setShortApiKey("Not available");
    }
  }, [session.access_token]);

  useEffect(() => {
    if (shortApiKey === "••••••••") {
      getApiKeyShort();
    }
  }, [shortApiKey, getApiKeyShort]);

  const handleRegenerateApiKey = useCallback(async () => {
    try {
      const result = await updateApiKey(session.access_token);
      setShortApiKey(result.shortApiKey + " ••••");
      await navigator.clipboard.writeText(result.api_key);
      showToast("CLIPBOARD_SUCCESS");
    } catch (error) {
      showToast("CLIPBOARD_ERROR");
    }
  }, [session.access_token, showToast]);

  const handleCopyApiKey = useCallback(async () => {
    try {
      const result = await fetchApiKey(session.access_token);
      await navigator.clipboard.writeText(result.api_key);
      showToast("CLIPBOARD_SUCCESS");
    } catch (error) {
      showToast("CLIPBOARD_ERROR");
    }
  }, [session.access_token, showToast]);

  const handleTestFileSelected = useCallback(
    async (file) => {
      try {
        !isLoading && setResponseJson("");
        setActivePreviewArea("json");

        setPrompt(getTestPrompt(file));
        setSchema(getTestSchema(file));
        setAttachment({ name: file });

        const response = await getFile(file);
        const fileContent = response.data;

        const fileObject = new File([fileContent], file, {
          type: response.headers["content-type"],
        });
        setAttachment(fileObject);
      } catch (err) {
        showToast("UNKNOWN_ERROR");
      }
    },
    [isLoading, showToast]
  );

  const handleSubmit = useCallback(async () => {
    setResponseJson("");
    setActivePreviewArea("json");
    setActiveChild(2);

    if (!prompt || !schema) {
      showToast("PROMPT_SCHEMA_ERROR");
      return;
    }

    setIsLoading(true);

    try {
      await generateResponse(
        prompt,
        schema,
        attachment,
        session.access_token,
        (chunk) => setResponseJson((prev) => prev + chunk),
        (error) => {
          showToast("REQUEST_ERROR");
        }
      );
    } finally {
      setIsLoading(false);
    }
  }, [
    prompt,
    schema,
    attachment,
    session.access_token,
    setResponseJson,
    setActivePreviewArea,
    setActiveChild,
    setIsLoading,
    showToast,
  ]);

  const handleClear = useCallback(() => {
    setPrompt("");
    setSchema("");
    setAttachment(null);
    setResponseJson("");
    setResetTrigger((prev) => !prev);
  }, []);

  const handleCopyPreviewArea = useCallback(() => {
    try {
      const clipboard =
        activePreviewArea === "json" ? responseJson : getPreviewAreaContent(activePreviewArea);

      navigator.clipboard.writeText(clipboard);
    } catch (e) {
      showToast("CLIPBOARD_ERROR");
    }
  }, [activePreviewArea, responseJson, showToast]);

  const previewAreaContent = useMemo(
    () => (activePreviewArea === "json" ? responseJson : getPreviewAreaContent(activePreviewArea)),
    [activePreviewArea, responseJson]
  );

  return (
    <div className="playground lg:pl-72 h-full max-h-full flex-grow">
      <Toaster />
      <div className="flex w-full text-left">
        <ResizeableSplitView activeChildIndex={activeChild}>
          <div className="flex flex-col p-4 w-full h-full">
            <PlaygroundTestData
              shortApiKey={shortApiKey}
              handleCopyApiKey={handleCopyApiKey}
              handleRegenerateApiKey={handleRegenerateApiKey}
              onOptionSelect={handleTestFileSelected}
              resetTrigger={resetTrigger}
              disabled={isLoading}
            />
            <PromptInput value={prompt} onChange={setPrompt} disabled={isLoading} />
            <OutputSchemaInput value={schema} onChange={setSchema} disabled={isLoading} />
            <Dropzone
              file={attachment}
              fileError={attachmentError}
              setFile={setAttachment}
              onDropAccepted={() => setAttachmentError(false)}
              onDropRejected={() => setAttachmentError(true)}
              disabled={isLoading}
            />
            <Buttons
              isLoading={isLoading}
              handleSubmit={handleSubmit}
              handleClear={handleClear}
              onNavigate={() => setActiveChild(2)}
            />
          </div>
          <div className="bg-gray-50 flex flex-col w-full h-full p-4">
            <div className="mb-2 w-full flex gap-2 justify-between">
              <NavButton onClick={() => setActiveChild(1)} direction={1} />
              <Dropdown
                active={activePreviewArea}
                onChange={(value) => setActivePreviewArea(value)}
              />
              <CopyToClipboardButton onClick={handleCopyPreviewArea} />
            </div>
            {isLoading && <LoadingBar />}
            <div className="h-full mt-1 overflow-y-auto rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500">
              <CodeOutput code={previewAreaContent} language={activePreviewArea} />
            </div>
          </div>
        </ResizeableSplitView>
      </div>
    </div>
  );
}

const PromptInput = memo(({ value, onChange, disabled }) => {
  return (
    <div className="flex flex-col mb-4 h-[30%] min-h-[25%]">
      <label className="text-lg font-bold leading-6 text-gray-800">Prompt</label>
      <p className="mb-1 text-sm text-gray-500">
        Craft your task description. Describe your file input and your desired response contents.
      </p>
      <textarea
        id="description"
        name="description"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="block w-full h-full text-sm rounded-md border border-gray-300 shadow-sm focus:border-indigo-600 focus:ring-indigo-500 overflow-y-auto"
        style={{ resize: "none" }}
        disabled={disabled}
      ></textarea>
    </div>
  );
});

const OutputSchemaInput = memo(({ value, onChange, disabled }) => {
  return (
    <div className="flex flex-col mb-2 h-[50%] min-h-[30%]">
      <label className="text-lg font-bold leading-6 text-gray-800">Output schema</label>
      <p className="mb-1 text-sm text-gray-500">
        JSON schema definition for structured output. You can find more details on our{" "}
        <a
          href={process.env.REACT_APP_CLIENT_BASE_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
        >
          Homepage
        </a>{" "}
        or check out the official{" "}
        <a
          href="https://json-schema.org/docs"
          target="_blank"
          rel="noopener noreferrer"
          className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
        >
          Docs{" "}
        </a>
        for creating your schema.
      </p>
      <div className="h-full max-h-full flex flex-col rounded-md border border-gray-300 shadow-sm focus:border-indigo-600 focus:ring-indigo-500 overflow-hidden">
        <CodeEditor value={value} onChange={onChange} language={"json"} readOnly={disabled} />
      </div>
    </div>
  );
});

const Buttons = memo(({ isLoading, handleSubmit, handleClear, onNavigate }) => {
  return (
    <div className="flex flex-row gap-3 mt-2">
      <motion.button
        className="w-2/3 flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 whitespace-nowrap text-sm font-medium text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={handleSubmit}
        disabled={isLoading}
        initial={false}
        animate={{
          scale: isLoading ? 1 : 1,
          opacity: isLoading ? 0.8 : 1,
          backgroundColor: "#3949AB",
        }}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.95 }}
        transition={{ duration: 0.3 }}
      >
        {isLoading ? (
          <>
            <Loader2 className="sm:mr-2 h-4 w-4 animate-spin" />
            <span className="hidden sm:inline">Generating...</span>
          </>
        ) : (
          <>
            <RefreshCw className="mr-2 h-4 w-4" />

            <span>
              {"Generate "}
              <span className="hidden sm:inline">JSON</span>
            </span>
          </>
        )}
      </motion.button>

      <motion.button
        className="w-1/3 flex items-center justify-center rounded-md bg-gray-600 px-3 py-2 whitespace-nowrap text-sm font-medium text-white shadow-sm focus:outline-none"
        onClick={handleClear}
        disabled={isLoading}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.95 }}
        animate={{
          scale: isLoading ? 1 : 1,
          opacity: isLoading ? 0.8 : 1,
        }}
        transition={{ duration: 0.2 }}
      >
        <Trash2 className="sm:mr-2 h-4 w-4" />
        <span className="hidden sm:inline">Clear UI</span>
      </motion.button>

      <NavButton onClick={onNavigate} direction={2} />
    </div>
  );
});

const NavButton = memo(({ onClick, direction }) => {
  return (
    <motion.button
      className="xl:hidden flex items-center justify-center px-3 rounded-md bg-gray-200 text-sm font-medium text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      onClick={onClick}
      initial={false}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.3 }}
    >
      {direction === 1 && <ArrowLeftFromLine className="text-indigo-600 h-4 w-4" />}
      {direction === 2 && <ArrowRightFromLine className="text-indigo-600 h-4 w-4" />}
    </motion.button>
  );
});
