import { PlusIcon } from '@heroicons/react/24/outline'
import SliderComponent from "../../ui/Slider.js";
import PaymentMethodsDropdown from '../payments/PaymentMethodsDropdown.js';
import { formatNumber } from '../../../utils/numbers.js';
import { useStripe } from '../../../contexts/StripeContext.js';

export default function CreditsDialogOrder({ onAddPaymentMethod, onCheckout, onPaymentMethodsError }) {
  const { amount, updateAmount } = useStripe();

  return (
    <>
      <p className="mb-6 text-center text-6xl">
        {`$${formatNumber(amount)}`}
      </p>
      <SliderComponent value={amount} onValueChange={updateAmount} />
      <p className="mt-6 mb-4">
        Add credits to your balance to use them for requests in Playground or by directly using the API.
      </p>
      <PaymentMethodsDropdown onPaymentMethodsError={onPaymentMethodsError}/>
      <div className="mt-2 flex justify-end">
        <button
          onClick={onAddPaymentMethod} 
          className="flex justify-center items-center text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
          <PlusIcon className="w-4 h-4"/>
          Add payment method
        </button>
      </div>
      <button
        type="button"
        className="mt-4 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={onCheckout}
      >
        Proceed to checkout
      </button>
    </>
  )
}