import { PlusIcon } from '@heroicons/react/20/solid'

export default function PaymentMethodsEmpty({ onAddCredits }) {
  return (
    <div className="pt-20 mt-2 text-center">
      <svg
        fill="none" 
        viewBox="0 0 24 24" 
        strokeWidth={0.8} 
        stroke="currentColor" 
        className="mx-auto h-12 w-12 text-gray-400">
        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No Payment methods registered</h3>
      <p className="mt-1 text-sm text-gray-500">Get started by adding credits to your account.</p>
      <div className="mt-6">
        <button
          type="button"
          onClick={onAddCredits}
          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          Add Credits
        </button>
      </div>
    </div>
  );
}