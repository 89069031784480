export default function DinersIcon() {
  return (
    <svg className="h-8 mr-2 w-8 sm:h-6 sm:flex-shrink-0" viewBox="0 0 750 500" aria-hidden="true">
      <rect width="750" height="500" fill="#0079BE" rx="90" />
      <path
        d="M584.934,237.947 C584.934,138.532 501.953,69.814 411.039,69.847 L332.797,69.847 C240.794,69.814 165.066,138.552 165.066,237.947 C165.066,328.878 240.794,403.587 332.797,403.151 L411.039,403.151 C501.953,403.587 584.934,328.858 584.934,237.947 Z"
        fill="#FFFFFF"
      />
      <path
        d="M333.28,83.931 C249.21,83.957 181.086,152.238 181.066,236.511 C181.086,320.768 249.21,389.043 333.28,389.069 C417.37,389.043 485.508,320.768 485.52,236.511 C485.508,152.238 417.37,83.957 333.28,83.931 Z"
        fill="#0079BE"
      />
      <path
        d="M237.066,236.098 C237.145,194.918 262.812,159.802 299.006,145.847 L299.006,326.327 C262.812,312.381 237.145,277.284 237.066,236.098 Z M368.066,326.373 L368.066,145.847 C404.273,159.768 429.98,194.904 430.046,236.104 C429.98,277.316 404.273,312.426 368.066,326.373 Z"
        fill="#FFFFFF"
      />
    </svg>
  );
}
