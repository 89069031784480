export const generateResponse = async (
  prompt,
  schema,
  attachment,
  accessToken,
  onChunk,
  onError
) => {
  try {
    const schemaFile = new Blob([schema], {
      type: "application/json",
    });

    const form = new FormData();
    form.append("prompt", prompt);
    form.append("schema", schemaFile);
    form.append("attachment", attachment);
    form.append("response", "stream");

    const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/generate`, {
      method: "POST",
      body: form,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      const responseText = await response.json();
      onChunk(JSON.stringify(responseText, null, 2));
      return;
    }

    const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
    while (true) {
      const { value, done } = await reader.read();
      if (done) break;
      onChunk(value);
    }
  } catch (error) {
    onError(error);
  }
};
