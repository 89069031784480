import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../ui/Input.js";
import { useAuth } from "../../contexts/AuthContext.js";
import Alert from "../ui/Alert.js";
import ChoosePasswordInput from "./ChoosePasswordInput.js";
import JsongenLogo from "../icons/JsongenLogo.js";
import { testEmail } from "../../utils/regex.js";

const FORGOT_PASSWORD_CONSTANTS = {
  SEND_RESET_MAIL: {
    titleText: "Reset your password",
    emailActionText: "Send password reset email",
  },
  RESET_PASSWORD: {
    titleText: "Choose your new password",
    emailActionText: "Reset password and login",
  },
};

export default function ForgotPasswordForm({ handleFormMessage }) {
  const [alertMessage, setAlertMessage] = useState("");
  const [isValidResetPassword, setIsValidResetPassword] = useState(false);

  const { session, sendResetPasswordMail, updatePassword } = useAuth();
  const action = session ? "RESET_PASSWORD" : "SEND_RESET_MAIL";
  const CONSTANTS = FORGOT_PASSWORD_CONSTANTS[action];

  const emailRef = useRef();
  const passwordRef = useRef();

  const navigate = useNavigate();

  const handleForgotPasswordAction = async (e) => {
    e.preventDefault();

    const email = emailRef.current?.value;

    if (!testEmail(email)) {
      setAlertMessage("Please provide a valid email address.");
      return;
    }

    const error = await sendResetPasswordMail({
      email: email,
      redirectTo: `${process.env.REACT_APP_CLIENT_BASE_URL}/reset-password`,
    });

    if (!error) {
      handleFormMessage(email);
    } else {
      console.error(error);
      setAlertMessage("Oops. Something went wrong. Please try again.");
    }
  };

  const handleResetPasswordAction = async (e) => {
    e.preventDefault();

    const error = await updatePassword(passwordRef.current?.value);
    if (!error) {
      navigate("/dashboard");
    } else {
      if (error.message?.includes("New password should be different from the old password.")) {
        setAlertMessage("New password should be different from the old password.");
      } else {
        setAlertMessage("Oops. Something went wrong. Please try again.");
      }
    }
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const params = new URLSearchParams(hash.substring(1));
      const error = params.get("error");
      if (error) {
        setAlertMessage(
          "Your recovery link is invalid or has expired. Do you want to request a new one?"
        );
      }
    }
  }, []);

  const disableActionButton = action === "RESET_PASSWORD" && !isValidResetPassword;

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <JsongenLogo textColor="text-indigo-700" braceColor="text-gray-600" />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            {CONSTANTS.titleText}
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            {alertMessage && <Alert message={alertMessage} />}
            <form className="mt-4 space-y-6">
              <Input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                disabled={action === "RESET_PASSWORD"}
                defaultValue={session?.user?.email}
                ref={emailRef}
                labelClasses="flex text-sm font-medium leading-6 text-gray-900"
                inputClasses="block w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                Email address
              </Input>

              {action === "RESET_PASSWORD" && (
                <ChoosePasswordInput
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  updateValidState={setIsValidResetPassword}
                  ref={passwordRef}
                  labelClasses="flex text-sm font-medium leading-6 text-gray-900"
                  inputClasses="block w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  New Password
                </ChoosePasswordInput>
              )}

              <button
                disabled={disableActionButton}
                onClick={
                  action === "RESET_PASSWORD"
                    ? handleResetPasswordAction
                    : handleForgotPasswordAction
                }
                className={`flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-600 ${
                  disableActionButton
                    ? "bg-indigo-200 cursor-not-allowed"
                    : "bg-indigo-600 hover:bg-indigo-500"
                }`}
              >
                {CONSTANTS.emailActionText}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
