import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext.js";
import ProtectedRoute from "./components/auth/ProtectedRoute.js";
import AuthPage from "./pages/AuthPage.js";
import ForgotPasswordPage from "./pages/ForgotPasswordPage.js";
import LandingPage from "./pages/LandingPage.js";
import DashboardPage from "./pages/DashboardPage.js";
import TestPage from "./pages/TestPage.js";
import SwaggerUIComponent from "./components/reference/SwaggerUi.js";
import NotFoundPage from "./pages/NotFoundPage.js";

function App() {
  return (
    <Router>
      <div className="App">
        <AuthProvider>
          <Routes>
            <Route path="/test" element={<TestPage />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/signin" element={<AuthPage type="SIGNIN" />} />
            <Route path="/signup" element={<AuthPage type="SIGNUP" />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ForgotPasswordPage />} />
            <Route path="/docs/api" element={<SwaggerUIComponent />} />
            <Route
              path="/dashboard/:selectedItem?"
              element={
                <ProtectedRoute redirectTo="/signin">
                  <DashboardPage />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </AuthProvider>
      </div>
    </Router>
  );
}

export default App;
